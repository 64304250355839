<template>
  <div class="login">
    <!-- logo -->
    <div class="logo" v-show="!protocolShow">
      <img src="../assets/logo/logo.png" alt="" draggable="false" />
    </div>
    <!-- 登录表单 -->
    <div class="loginBox" v-show="!protocolShow">
      <div class="mask-close pc" @click="doGoback()">
        <img
          src="@/assets/home/back.png"
          class="closeImg"
          alt=""
          draggable="false"
        />
      </div>
      <div class="mask-close phone" @click="doGoback()">
        <img
          src="@/assets/home/back.png"
          class="closeImg"
          alt=""
          draggable="false"
        />
      </div>
      <h4>会员注册</h4>
      <div class="form">
        <div class="oneInput">
          <img src="../assets/login/phone.png" alt="" /><span>手机号码</span>
        </div>
        <div class="textInput">
          <input
            type="text"
            autocomplete="off"
            v-model="regForm.loginName"
            placeholder="请输入手机号"
          />
        </div>
        <div class="oneInput">
          <img src="../assets/login/yzm.png" alt="" /><span>验证码</span>
        </div>
        <div class="textInput">
          <input
            type="text"
            autocomplete="off"
            v-model="regForm.yzm"
            placeholder="请输入验证码"
          />
          <div class="sendCode" @click="sendCode">{{ sendCodeMsg }}</div>
        </div>
        <div class="oneInput">
          <img src="../assets/login/password.png" alt="" /><span>登录密码</span>
        </div>
        <div class="textInput">
          <input
            type="password"
            autocomplete="off"
            v-model="regForm.password"
            placeholder="请输入密码"
          />
          <!--  <div class="sendCode">发送验证码</div> -->
        </div>
        <div class="oneInput">
          <img src="../assets/login/password.png" alt="" /><span>确认密码</span>
        </div>
        <div class="textInput">
          <input
            type="password"
            autocomplete="off"
            v-model="rePassword"
            placeholder="请输入再次密码"
          />
          <!--  <div class="sendCode">发送验证码</div> -->
        </div>
        <div class="oneInput">
          <img src="../assets/login/user.png" alt="" /><span>会员昵称</span>
        </div>
        <div class="textInput">
          <input
            type="text"
            autocomplete="off"
            v-model="regForm.nickName"
            placeholder="请输入昵称"
          />
        </div>

        <div class="oneInput">
          <img src="../assets/login/user.png" alt="" /><span>邮箱</span>
        </div>
        <div class="textInput">
          <input type="text" v-model="regForm.email" placeholder="请输入邮箱" />
          <!--  <div class="sendCode">发送验证码</div> -->
        </div>
        <!--   <div class="oneInput">
          <img src="../assets/login/password.png" alt="" /><span>会员性别</span>
        </div>
        <div class="textInput">
          <input type="password" placeholder="请输入再次密码" />
        </div> -->

        <!--   <div class="oneInput">
          <img src="../assets/login/location.png" alt="" /><span>地区</span>
        </div>
        <div class="textInput">
          <input class="textInput" type="text" placeholder="请选择地区" />
        </div> -->

        <!-- <div class="oneInput">
          <img src="../assets/login/phone.png" alt="" /><span>绑定手机</span>
        </div>
        <div class="textInput">
          <input type="text" placeholder="请输入手机号" />
          <div class="sendCode">发送验证码</div>
        </div> -->
        <div class="protocol">
          <input type="checkbox" v-model="isAgree" id="protocolBox" />
          <label class="regContent" @click="toProtocol"> 服务协议</label>
        </div>
        <div class="textInput">
          <button @click="doReg" class="loginbtn">注册</button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Protocol
        :protocolShow="protocolShow"
        :isAgree="isAgree"
        @changeProtocolShow="changeProtocolShow"
        @changeAgree="changeAgree"
        v-if="protocolShow"
      ></Protocol>
    </transition>
  </div>
</template>

<script>
import Protocol from "@/components/login/protocol.vue";

export default {
  components: {
    Protocol,
  },
  data() {
    return {
      protocolShow: false,
      isAgree: false,
      sendCodeMsg: "发送验证码",
      codeStatus: false,
      code:'',
      regForm: {
        loginName: "",
        password: "",
        yzm: "",
        phoneType: "",
        nikeName: "",
        email: "",
      },
      rePassword: "",
    };
  },
  mounted() {
    if (localStorage.getItem("regForm") != null) {
      this.regForm = JSON.parse(localStorage.getItem("regForm"));
      this.codeStatus = localStorage.getItem('codeStatus')
      this.code = localStorage.getItem('code')
       localStorage.removeItem('regForm') 
       localStorage.removeItem('codeStatus') 
       localStorage.removeItem('code') 
    }
  },
  methods: {
    changeProtocolShow() {
      this.protocolShow = false;
    },
    changeAgree(agree) {
      this.isAgree = !agree;
    },
    toProtocol() {
      localStorage.setItem("regForm", JSON.stringify(this.regForm));
      localStorage.setItem('codeStatus' , this.codeStatus)
      localStorage.setItem('code' , this.code)
      this.$router.push({ name: "Protocol" });
    },
    doReg() {
      var _this = this;
      if (this.codeStatus != true && this.codeStatus!='true' ) {
        this.$layer.msg("请先获取验证码!");
        return false;
      }
      if (this.regForm.loginName == "" || this.regForm.loginName.length != 11) {
        this.$layer.msg("请输入手机号码!");
        return false;
      }
      var reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;

      if (!reg.test(this.regForm.loginName)) {
        this.$layer.msg("请正确输入手机号码!");
        return false;
      }

      if (this.regForm.yzm == "") {
        this.$layer.msg("请输验证码!");
        return false;
      }
      if (this.regForm.password == "" || this.regForm.password.length < 6) {
        this.$layer.msg("请正确输入密码!");
        return false;
      }
      if (this.regForm.nickName == "") {
        this.$layer.msg("请输入用户名!");
        return false;
      }
      var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (!myreg.test(this.regForm.email)) {
        this.$layer.msg("请正确输入邮箱!");
        return false;
      }

      if (this.regForm.password != this.rePassword) {
        this.$layer.msg("请正确输入密码!");
        return false;
      }
      if (this.isAgree == false) {
        this.$layer.msg("请先同意注册协议!");
        return false;
      }
      if(this.regForm.yzm !=this.code){
        this.$layer.msg("请正确输入验证码!");
        return false;
      }
      this.$axios({
        method: "post",
        url:
          _this.$global.serverSrc +
          "/api/register?loginName=" +
          this.regForm.loginName +
          "&password=" +
          this.regForm.password +
          "&yzm=" +
          this.regForm.yzm +
          "&nickname=" +
          this.regForm.nickName +
          "&email=" +
          this.regForm.email +
          "&name=" +
          this.regForm.nikeName,
        // data: {loginName:this.regForm.loginName , password:this.regForm.password},
      }).then((res) => {
        if (res.data.code == 0) {
          localStorage.removeItem("regForm");
          _this
            .$axios({
              method: "post",
              url:
                _this.$global.serverSrc +
                "/api/auth/pwd?loginName=" +
                _this.regForm.loginName +
                "&password=" +
                _this.regForm.password,
              // data: {loginName:this.regForm.loginName , password:this.regForm.password},
            })
            .then((res) => {
              if (res.data.code == 0) {
                _this.$cookies.set("token", res.data.result.token, "24d");
                _this.$layer.msg("登录成功!");
                window.localStorage.setItem("loginName", this.loginName);
                _this.$router.push({ name: "home" });
              } else {
                _this.$layer.msg(res.data.message);
              }
            });
        }else{
          _this.$layer.msg(res.data.message);
        }
      });
    },
    doGoback() {
      this.$router.back();
    },
    sendCode() {
      var _this = this;
      if (this.regForm.loginName == "" || this.regForm.loginName.length != 11) {
        this.$layer.msg("请输入手机号码!");
        return false;
      }
      var reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;

      if (!reg.test(this.regForm.loginName)) {
        this.$layer.msg("请正确输入手机号码!");
        return false;
      }

      this.$axios({
        method: "post",
        url:
          _this.$global.serverSrc +
          "/api/send/code?phone=" +
          this.regForm.loginName +
          "&type=1",
        // data: {loginName:this.regForm.loginName , password:this.regForm.password},
      }).then((res) => {
        if (res.data.code == 0) {
          this.$layer.msg("验证码获取成功");
          this.showTimes();
          this.code = res.data.result
          this.codeStatus = true;
        }
      });
    },
    showTimes(time = 60) {
      time--;
      this.sendCodeMsg = time;

      var _this = this;
      setTimeout(function () {
        if (time > 0) {
          _this.showTimes(time);
        } else {
          _this.sendCodeMsg = "重新获取";
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;

  .logo {
    width: 36%;
    position: absolute;
    left: 4%;
    top: 2%;
    max-width: 210px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.regContent {
  cursor: pointer;
}
.loginBox {
  top: 50%;
  width: 92%;
  max-width: 650px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  max-height: 80%;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  .mask-close {
    float: right;
    .closeImg {
      width: 15px;
      height: 15px;
    }
  }
  h4 {
    letter-spacing: 0.1em;
  }
  .form {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 10px;

    font-size: 12px;
    .oneInput {
      width: 100%;
      margin-top: 10px;
      img {
        height: 16px;
        width: auto;
        vertical-align: text-bottom;
        margin-right: 3px;
        transform: scale(0.8);
      }
      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .textInput {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        padding-top: 10px;
        padding-left: 5px;
        padding-bottom: 10px;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #ededed;
        outline: none;
        border-radius: none;
        font-size: 14px;
        user-select: auto;
      }
      .sendCode {
        position: absolute;
        right: 0;
        top: 50%;
        width: 100px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        background-color: #ccc;
        transform: translateY(-50%);
      }
    }
    .protocol {
      text-align: center;
      margin-top: 15px;
      cursor: pointer;
    }
    .loginbtn {
      width: 100%;
      background-color: #000;
      color: #fff;
      border-radius: 25px;
      padding: 10px 0;
      box-sizing: border-box;
    }
  }
}
</style>