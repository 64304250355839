<template>
  <div class="mask solution">
    <div class="maskBox">
      <!-- logo -->
      <div class="mask-logo">
        <img src="@/assets/logo/logo.png" alt="" draggable="false" />
      </div>
      <!-- 关闭按钮  -->
      <div class="mask-close" @click="closeMask()">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <!-- 弹窗内容 -->
      <div class="mask-container">
        <h4>解决方案</h4>
        <img src="@/assets/home/mask/solution1.png" alt="" draggable="false" />
        <img src="@/assets/home/mask/solution2.png" alt="" draggable="false" />
        <h4>核心竞争力</h4>
        <img src="@/assets/home/mask/solution3.png" alt="" draggable="false" />
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeMask() {
      this.$emit("closeMask");
    },
  },
};
</script>

<style>
</style>