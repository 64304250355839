<template>
  <div style="width: 100%; height: 100%; overflow: hidden; position: absolute">
    <router-view></router-view>
    <WaterMask ref="watermask"></WaterMask>
  </div>
</template>
<script>
import WaterMask from "@/components/mask/waterMask.vue";
// import axios from "axios";
export default {
  name: "App",
  components: {
    WaterMask,
  },
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    let _this = this;
    // 监听三指下滑
    let istouch = true;
    window.onload = function () {
      document.addEventListener("touchstart", (target) => {
        istouch = true;
        document.addEventListener("touchmove", (target) => {
          if (istouch) {
            if (target.touches.length >= 3) {
              _this.$refs.watermask.watermarkShow();
            }
          }
        });
      });
      document.addEventListener("touchend", () => {
        istouch = false;
        _this.$refs.watermask.watermarkHide();
      });
      // 监听键盘键入
      let keydown = false;
      document.onkeydown = function (event) {
        if (
          _this.$route.name !== "home" ||
          _this.$store.state.mask === "showInfo"
        )
          return;
        if (
          v3d.puzzles.procedures.showWatermask &&
          event.keyCode !== 87 &&
          event.keyCode !== 83 &&
          event.keyCode !== 65 &&
          event.keyCode !== 68
        ) {
          if (
            _this.$store.state.nav !== 4 ||
            _this.$store.state.mask === 9 ||
            v3d.puzzles.procedures.showWatermask() === "scene"
          ) {
            _this.$refs.watermask.watermarkShow();
          }
          clearTimeout(_this.timer);
          _this.timer = setTimeout(() => {
            _this.$refs.watermask.watermarkHide();
            v3d.puzzles.procedures.hideWatermask();
          }, 500);
        }
      };
      window.onblur = function () {
        if (_this.$route.name !== "home") return;
        if (v3d.puzzles.procedures.showWatermask == undefined) return;
        if (v3d.puzzles.procedures.showWatermask() === "scene") {
          _this.$refs.watermask.watermarkShow();
        }
        setTimeout(() => {
          _this.$refs.watermask.watermarkHide();
          v3d.puzzles.procedures.hideWatermask();
        }, 500);
      };
    };
  },
  created() {},
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  user-select: none;
}
html,
body {
  width: 100%;
  height: 100%;
}
ul,
dl {
  list-style: none;
}
.fade-enter,
.fade-leave-to {
  /* //进入前，离开后 */
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  /* //进入后，离开前 */
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
</style>
