<template>
  <div class="mask clothMask">
    <div class="maskBox">
      <div class="mask-logo">
        <img src="@/assets/logo/logo.png" alt="" draggable="false" />
      </div>
      <div class="mask-close" @click="closeMask">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <div class="mask-container">
        <div class="videoResource">
          <!--   <img
            v-if="clothImgList[currentCloth - 1].gif !== null"
            :src="clothImgList[currentCloth - 1].gif"
            alt=""
            draggable="false"
          /> -->
          <video
            v-if="clothImgList[currentCloth - 1].video"
            controls
            :src="clothImgList[currentCloth - 1].video"
            ref="clothVideo"
            webkit-playsinline="true"
            playsinline="true"
          ></video>
        </div>
        <div class="circle">
          <h4>{{ clothImgList[currentCloth - 1].name }}</h4>
          <div v-html="clothImgList[currentCloth - 1].content"></div>
        </div>
        <div class="profile">
          <img
            :src="clothImgList[currentCloth - 1].src"
            alt=""
            draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentCloth"],
  data() {
    return {
      clothImgList: [
        {
          name: "",
          video: "",
          content: "",
          src: "",
        },
      ],
    };
  },

  methods: {
    closeMask() {
      this.$emit("closeMask");
    },

    getClothImgData() {
      var _this = this;
      var list = [];

    
        this.$axios
        .post(_this.$global.serverSrc + "/api/get/material/list?userid="+this.$cookies.get("userid"), {})
          .then((data) => {
            data.data.result.forEach((item, index) => {
              list[parseInt(item.buyId) - 1] = item;
            });
            this.clothImgList = list;
        });
     
     
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    showDetails(val) {
      var _this = this;
       var list = [];
       var materiallist = []
      var phoneType = "app";
      if (!this._isMobile()) {
        phoneType = "pc";
      }
      // console.log(this.clothImgList)
      // console.log(val)


      if(localStorage.getItem('shareJson')){
         console.log(this.$route.query.product)
         if(this.$route.query.product==JSON.parse(localStorage.getItem('shareJson')).modelName.split("_")[1]){
           materiallist =  JSON.parse(localStorage.getItem('shareJson')).materialVos 
        
           materiallist.forEach((item, index) => {
              list[parseInt(item.buyId) - 1] = item;
            });
            this.clothImgList = list;
         }else{
           localStorage.removeItem('shareJson')
         }
         
      } else{
         this.$axios
          .post(_this.$global.serverSrc + "/api/get/material/list?userid="+this.$cookies.get("userid"), {})
            .then((data) => {
              data.data.result.forEach((item, index) => {
                list[parseInt(item.buyId) - 1] = item;
              });
              this.clothImgList = list;
          });
      }



      this.$axios
        .post(
          _this.$global.serverSrc +
            "/api/get/material/details?id=" +
            this.clothImgList[val - 1].id +
            "&visitType=" +
            phoneType,
          {}
        )
        .then((data) => {
          // console.log(this.clothImgList[val])
        });
    },
  },
  mounted() {
    this.getClothImgData();
  },
};
</script>

<style lang="less">
.circle div img {
  width: 100%;
}
</style>