<template>
  <div class="mask videoMask">
    <div class="videoBox">
      <div class="mask-close" @click="closeMask()">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <video
        autoplay
        webkit-playsinline="true"
        playsinline="true"
        controls
        :src="videoList[currentVideo - 1].src"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentVideo"],
  data() {
    return {
      videoList: [
        { src: require("@/assets/video/1.mp4") },
        { src: require("@/assets/video/2.mp4") },
        { src: require("@/assets/video/3.mp4") },
      ],
    };
  },
  methods: {
    closeMask() {
      this.$emit("closeMask");
    },
  },
};
</script>

<style>
</style>