<template>
<div class="protocolBg">
<div class="protocol">
    <div class="protocol-top">
      <img src="@/assets/protocol/protocol-top.png" alt="" />
      <img
        @click="changeProtocolShow"
        class="back"
        src="@/assets/protocol/back.png"
        alt=""
      />
      <div class="protocol-logo">
        <img src="@/assets/protocol/protocol-logo.png" alt="" />
        <div class="company">
          <h4>广东盈通新材料有限公司</h4>
        </div>
      </div>
    </div>
    <div class="protocol-bottom">
      <input
        type="checkbox"
        id="protocolBox1"
        @click="changeAgree"
        v-model="protocolAgree"
      /><label for="protocolBox1" style="margin-left: 5px">服务协议</label>
    </div>
    <div class="protocol-box" v-html="registerContent" >
      
    </div>
  </div>
</div>
  
</template>

<script>
export default {
  props: ["protocolShow", "isAgree"],
  data() {
    return {
      protocolHeight: "",
      circleList: '',
      registerContent:'',
      protocolAgree: !this.isAgree,
    };
  },
  mounted() {
    
    
  },
  created() {
    this.getRegiterContent()
  },
  methods: {
    changeProtocolShow() {
      this.$emit("changeProtocolShow");
    },
    changeAgree() {
      this.$emit("changeAgree", this.protocolAgree);
    },
    getRegiterContent(){
    var _this = this
      _this.$axios.post(_this.$global.serverSrc+"/api/get/config/info",{})
      .then((data) => {
        console.log(data)
          if(data.data.result){
            _this.registerContent = data.data.result.registerContent
          }
      })     
    }
  },
};
</script>

<style lang="less" scoped>

.protocol {
  max-width: 600px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #f5f5f5;
  .protocol-top {
    position: relative;
    width: 100%;
    // height: 100%;
    & > img {
      width: 100%;
      height: auto;
    }
    .back {
      width: 5%;
      position: absolute;
      right: 5%;
      top: 18%;
      cursor: pointer;
    }
    .protocol-logo {
      width: 100%;
      position: relative;
      transform: translateY(-50%);
      text-align: center;
      img {
        width: 26%;
        height: auto;
      }
      .company {
        width: 100%;
        position: absolute;
        top: 100%;
        text-align: center;
      }
    }
  }
  .protocol-bottom {
    width: 92%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .protocol-box {
    width: 92%;
    background-color: #fff;
    position: relative;
    top: 0;
    bottom: 0;
    left: 50%;
    overflow-y: scroll;
    height: 50vh;
    transform: translateX(-50%);
    overflow: auto;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
    color: #333;
    &::-webkit-scrollbar {
      display: none;
    }
    h4 {
      text-indent: 2em;
      margin-bottom: 15px;
    }
    p {
      text-indent: 2em;
      margin-bottom: 15px;
      font-size: 12px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }
}
 
@media screen and (max-width: 600px) {
    .protocol {
        width: 100%;
    }  
}
</style>