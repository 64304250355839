<template>
  <div class="mask machineWall">
    <div class="machineBox" ref="machineBox">
      <div
        class="mask-close pc"
        @click="closeMask()"
        :style="{ right: right + 'px' }"
      >
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <div class="mask-close phone" @click="closeMask()">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
     
         <div class="machineImg" v-html="machineImg.appContent" v-if="isPhone">  </div>
          <div class="machineImg" v-html="machineImg.pcContent" v-else> </div>
        <!-- <img class="phone" :src="machineImg[currentMachine - 1].phone" alt="" />
        <img
          @load="loadImg"
          ref="machineImg"
          class="pc"
          :src="machineImg[currentMachine - 1].pc"
          alt=""
        /> -->
     
    </div>
  </div>
</template>

<script>
export default {
 // props: ["currentMachine"],
  data() {
    return {
      machineImg:   {
          content:'',
          appContent:'',
          pcContent:''
         
        },
      
         
       
      right: 0,
        isPhone:true
    };
  },
  watch: {
    right(val) {
      let that = this;
    },
  },
  mounted() {
    window.addEventListener("resize", this.loadImg);
       var sUserAgent = navigator.userAgent.toLowerCase();
          if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
          //跳转移动端页面
              this.isPhone = true
          } else {
          //跳转pc端页面
           this.isPhone =false
          }
  },
  destroyed() {
    window.removeEventListener("resize", this.loadImg);
  },
  methods: {
    

    closeMask() {
      this.$emit("closeMask");
    },
    showInfo(index){
        this.machineImg =JSON.parse(localStorage.getItem('machineImg'))[index-1]
           
    }
  },
};
</script>

<style>
.machineImg{
    max-width: 1200px;
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
}
.machineImg p{
  height: 100%;
}
.machineImg p img{
  height: 100%;
  width:auto;
}
</style>