<template>
  <div class="waterMask"></div>
</template>

<script>
import watermark from "watermark-dom";

export default {
  name: "WaterMask",
  data() {
    return {
      loginName: window.localStorage.getItem("loginName")
        ? window.localStorage.getItem("loginName")
        : "",
    };
  },
  methods: {
    watermarkShow() {
      watermark.init({
        watermark_txt: "广东盈通新材料有限公司 ", //水印的内容
        watermark_color: "#000", //水印字体颜色
        watermark_fontsize: "16px", //水印字体大小
        watermark_alpha: 0.5, //水印透明度，要求设置在大于等于0.005
        watermark_x_space: 50, //水印x轴间隔
        watermark_y_space: 150,
        watermark_x: 0, //水印起始位置x轴坐标
        watermark_y: 0,
        watermark_angle: 45, //水印倾斜度数
        watermark_parent_width: 2040,
        watermark_parent_height: 1080,
        watermark_height: 30, //水印长度
        watermark_width: 180, //水印宽度
      });
    },
    watermarkHide() {
      watermark.init({
        watermark_txt: `广东盈通新材料有限公司 ${this.loginName}`, //水印的内容
        watermark_color: "rgba(0,0,0,1)", //水印字体颜色
        watermark_fontsize: "14px", //水印字体大小
        watermark_alpha: 0.005, //水印透明度，要求设置在大于等于0.005
        watermark_font: "'Courier New', Courier, monospace ",
        watermark_x_space: 0, //水印x轴间隔
        watermark_y_space: 0,
        watermark_x: 0, //水印起始位置x轴坐标
        watermark_y: 0,
        watermark_angle: 45, //水印倾斜度数
        watermark_height: 80, //水印长度
        watermark_width: 180, //水印宽度
        watermark_parent_width: 2040,
        watermark_parent_height: 1080,
      });
    },
  },
  created() {},
  mounted() {
    this.watermarkHide();
  },
};
</script>

<style lang="less" >
</style>
