<template>
  <div class="login mask">
    <!-- 登录表单 -->
    <div class="loginBox">
      <div class="mask-close pc" @click="closeMask()">
        <img
          src="@/assets/home/mask/close.png"
          class="closeImg"
          alt=""
          draggable="false"
        />
      </div>
      <div class="mask-close phone" @click="closeMask()">
        <img
          src="@/assets/home/mask/close.png"
          class="closeImg"
          alt=""
          draggable="false"
        />
      </div>
      <h4>会员信息</h4>
      <div class="form">
        <div class="oneInput">
          <img src="../../assets/login/phone.png" alt="" /><span>手机号码</span>
        </div>
        <div class="textInput">
          <input
            type="text"
            v-model="userInfo.phone"
            placeholder="请输入手机号"
          />
        </div>

        <div class="oneInput">
          <img src="../../assets/login/user.png" alt="" /><span>会员昵称</span>
        </div>
        <div class="textInput">
          <input type="text" v-model="userInfo.name" placeholder="请输入昵称" />
        </div>

        <div class="oneInput">
          <img src="../../assets/login/user.png" alt="" /><span>邮箱</span>
        </div>
        <div class="textInput">
          <input
            type="text"
            v-model="userInfo.email"
            placeholder="请输入邮箱"
          />
        </div>

        <div class="textInput">
          <button @click="doEdit" class="loginbtn">修改</button>
        </div>

        <div class="protocol">
          <label class="regContent" @click="dologout"> 退出登录 </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      var _this = this;
      this.$axios({
        method: "post",
        url: _this.$global.serverSrc + "/api/get/user/info",
      }).then((res) => {
        if (res.data.code == 0) {
          _this.userInfo = res.data.result;
          _this.userInfo.password = "";
        }
      });
    },
    dologout() {
      this.$cookies.remove("token");
      this.$router.push({ name: "login" });
    },
    doEdit() {
      var _this = this;
      this.$axios({
        method: "post",
        url:
          _this.$global.serverSrc +
          "/api/update/user/info?phone=" +
          this.userInfo.phone +
          "&email=" +
          this.userInfo.email +
          "&name=" +
          this.userInfo.name,
      }).then((res) => {
        if (res.data.code == 0) {
          _this.$layer.msg("修改成功!");
          return false;
        }
      });
      this.editstate = false;
    },
    closeMask() {
      this.$emit("closeMask");
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  .mask-close {
    float: right;
    cursor: pointer;
    .closeImg {
      width: 15px;
      height: 15px;
    }
  }
  .logo {
    width: 36%;
    position: absolute;
    left: 4%;
    top: 2%;
    max-width: 210px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.regContent {
  cursor: pointer;
}
.loginBox {
  pointer-events: all;
  top: 50%;
  width: 92%;
  max-width: 650px;
  position: absolute;
  left: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  padding: 10px;
  max-height: 80%;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  h4 {
    letter-spacing: 0.1em;
  }
  .form {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 10px;

    font-size: 12px;
    .oneInput {
      width: 100%;
      margin-top: 10px;
      img {
        height: 16px;
        width: auto;
        vertical-align: text-bottom;
        margin-right: 3px;
        transform: scale(0.8);
      }
      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .textInput {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        padding-top: 10px;
        padding-left: 5px;
        padding-bottom: 10px;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #ededed;
        outline: none;
        border-radius: none;
        font-size: 14px;
        user-select: auto;
      }
      .sendCode {
        position: absolute;
        right: 0;
        top: 50%;
        width: 100px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        background-color: #ccc;
        transform: translateY(-50%);
      }
    }
    .protocol {
      text-align: center;
      margin-top: 15px;
      cursor: pointer;
    }
    .loginbtn {
      width: 100%;
      background-color: #000;
      color: #fff;
      border-radius: 25px;
      padding: 10px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
</style>