import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import layer from 'vue-layer'
import global_ from './global/global'

import 'vue-layer/lib/vue-layer.css';
import MintUI from 'mint-ui'
Vue.use(MintUI)
import 'mint-ui/lib/style.css'
Vue.prototype.$layer = layer(Vue);
Vue.config.productionTip = false
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

axios.interceptors.request.use(
  config => {
      if (VueCookies.isKey('token')) {
          config.headers.Authorization = VueCookies.get("token");//把localStorage的token放在Authorization里
      }
      return config;
  },
  function(err) {
      console.log("失败信息" + err);
  }
);
 
 
Vue.prototype.$axios = axios
Vue.prototype.$global = global_
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 微信分享卡片
// axios
//   .get("https://wechat.viewspread.com/home/getjsapi", {
//     params: {
//       url: location.href.split("#")[0],
//     },
//   })
//   .then((data) => {
//     data = data.data;
//     wx.config({
//       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//       appId: data.appId, // 必填，公众号的唯一标识
//       timestamp: data.timestamp, // 必填，生成签名的时间戳
//       nonceStr: data.nonceStr, // 必填，生成签名的随机串
//       signature: data.signature.toLowerCase(), // 必填，签名
//       jsApiList: [
//         "onMenuShareTimeline",
//         "onMenuShareAppMessage",
//         "onMenuShareQQ",
//       ],
//     });
//     // 分享
//     wx.ready(function () {
//       //分享到朋友圈
//       wx.onMenuShareTimeline({
//         title: "广东盈通新材料线上产品展示", // 分享标题
//         desc: "为您呈现我们所有的产品应用，助您更深入了解盈通", // 分享描述
//         // link: "78788787", // 分享链接
//         imgUrl: "https://wechat.viewspread.com/images/yetom.png", // 分享图标
//         success: function () { },
//       });
//       //分享给朋友
//       wx.onMenuShareAppMessage({
//         title: "广东盈通新材料线上产品展示", // 分享标题
//         desc: "为您呈现我们所有的产品应用，助您更深入了解盈通", // 分享描述
//         // link: "<?php echo $weixin_share_url; ?>", // 分享链接
//         imgUrl: "https://wechat.viewspread.com/images/yetom.png", // 分享图标
//         type: "", // 分享类型,music、video或link，不填默认为link
//         dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
//         success: function () {
//           // alert('123')
//         },
//         fail: function (res) {
//           // console.log(res)
//         },
//       });
//       //分享到QQ
//       wx.onMenuShareQQ({
//         title: "广东盈通新材料线上产品展示", // 分享标题
//         desc: "为您呈现我们所有的产品应用，助您更深入了解盈通", // 分享描述
//         // link: "<?php echo $weixin_share_url; ?>", // 分享链接
//         imgUrl: "https://wechat.viewspread.com/images/yetom.png", // 分享图标
//         success: function () { },
//       });
//     });
//   })
//   .catch((error) => {
//     console.log(error);
//   });

