import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 当前导航
    nav: 1,
    // 当前遮罩
    mask: 1,
  },
  mutations: {
    // 更新nav
    updateNav(state, data) {
      state.nav = data
    },
    // 更新遮罩
    updateMask(state, data) {
      state.mask = data
    }
  },
  actions: {
  },
  modules: {
  }
})
