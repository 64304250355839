<template>
  <div class="productPage">
    <!-- logo -->
    <div class="logo">
      <img src="../assets/logo/logo.png" alt="" draggable="false" />
    </div>
    <!-- 左侧类别 手机-->
    <div class="product-container phone">
      <ul class="left-Aside">
        <li
          v-for="(item, index) in productType"
          :key="index"
          @click="changeType(index + 1)"
          :class="currentType === index + 1 ? 'activeType' : ''"
        >
          <img class="product-type" :src="item.src" alt="" />
          <p>{{ item.title }}</p>
          <div class="type-mask"></div>
        </li>
      </ul>
      <ul class="right-Aside">
        <template v-for="(item, index) in clothList">
          <li v-if="item.type === currentType" :key="index">
            <p>{{ item.name }}</p>
            <img class="cloth" :src="item.src" alt="" />
            <div class="customMade" @click="goModel(item.productId, item.id)">
              <img src="../assets/product/dingzhi.png" alt="" />
              <span>开始定制</span>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <!-- 左侧类别 电脑 -->
    <div class="product-container pc">
      <ul class="left-Aside">
        <li
          v-for="(item, index) in productType"
          :key="index"
          @click="changeType(index + 1)"
          :class="currentType === index + 1 ? 'activeType' : ''"
        >
          <img class="product-type" :src="item.src" alt="" />
          <p>{{ item.title }}</p>
          <div class="type-mask"></div>
        </li>
      </ul>
      <ul class="right-Aside">
        <template v-for="item1 in 5">
          <swiper
            v-if="currentType === item1"
            :key="item1"
            :options="swiperOption"
            ref="mySwiper"
          >
            <template v-for="(item, index) in clothList">
              <swiper-slide v-if="item.type === item1" :key="index">
                <div
                  :class="`swiper-box custom${item.id}Made${item.type}`"
                  @click="goModel(item.productId, item.id)"
                >
                  <p>{{ item.name }}</p>
                  <img class="cloth" :src="item.src" alt="" />
                  <div :class="`customMade `">
                    <img src="../assets/product/dingzhi.png" alt="" />
                    <span>开始定制</span>
                  </div>
                </div>
              </swiper-slide>
            </template>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: ["clothList", "currentType"],
  data() {
    return {
      productType: [
        {
          src: require("../assets/product/left1.jpg"),
          title: "户外休闲运动",
        },
        {
          src: require("../assets/product/left2.jpg"),
          title: "商务服装",
        },
        {
          src: require("../assets/product/left3.jpg"),
          title: "军工劳保",
        },
        {
          src: require("../assets/product/left4.jpg"),
          title: "鞋材/内衣",
        },
        {
          src: require("../assets/product/left5.png"),
          title: "盈通产品",
        },
      ],

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          click: function (event, target) {
            if (
              document.querySelector(
                "." + event.target.className.split(" ")[1]
              ) &&
              document
                .querySelector("." + event.target.className.split(" ")[1])
                .classList[1].indexOf("custom") >= 0
            ) {
              if (
                document.querySelectorAll(
                  "." + event.target.className.split(" ")[1]
                ).length > 1
              ) {
                for (
                  let i = 0;
                  i <
                  document.querySelectorAll(
                    "." + event.target.className.split(" ")[1]
                  ).length;
                  i++
                )
                  document
                    .querySelectorAll(
                      "." + event.target.className.split(" ")[1]
                    )
                    [i].click();
              } else {
                document
                  .querySelector("." + event.target.className.split(" ")[1])
                  .click();
              }
            }
          },
        },
      },
    };
  },
  methods: {
    changeType(index) {
      this.$emit("sendProductType", index);
    },
    goModel(productId, id) {
      this.$emit("sendCurrentNav", id);
    },
  },
};
</script>

<style lang="less">
@media (max-width: 512px) {
  .productPage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    .product-container {
      position: absolute;
      width: 92%;
      left: 50%;
      transform: translateX(-50%);
      top: 10%;
      bottom: 0;
      // background-color: pink;
      .left-Aside {
        position: absolute;
        left: 0;
        width: 34%;
        height: 100%;
        overflow: auto;
        padding-top: 2%;
        box-sizing: border-box;
        background-color: #fff;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          width: 100%;
          position: relative;
          margin-bottom: 20%;
          cursor: pointer;
          &:last-child {
            margin-bottom: 100%;
          }
          .product-type {
            width: 100%;
            height: auto;
            vertical-align: bottom;
            box-sizing: border-box;
            border-radius: 10px;
          }
          p {
            line-height: 28px;
            text-align: center;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.05em;
          }
          .type-mask {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.45);
            border-radius: 10px;
          }
        }
        .activeType {
          background-color: transparent;
          .product-type {
            border: 4px solid rgb(51, 51, 51);
          }
          p {
            background-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.8) 50%,
              rgba(0, 0, 0, 0.2) 100%
            );
          }
          .type-mask {
            background-color: transparent;
          }
        }
      }
      .right-Aside {
        position: absolute;
        width: 56%;
        height: 100%;
        right: 0;
        overflow: auto;
        padding: 2%;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          width: 100%;
          position: relative;
          margin-bottom: 8%;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
          border-radius: 10px;
          padding-bottom: 0;
          box-sizing: border-box;
          overflow: hidden;
          &:last-child {
            margin-bottom: 80%;
          }
          p {
            padding: 5%;
            font-size: 12px;
            color: #333;
          }
          .cloth {
            width: 100%;
            height: auto;
          }
          .customMade {
            float: right;
            font-size: 12px;
            background-color: #3a3a3a;
            display: inline;
            border-top-left-radius: 10px;
            color: #fff;
            padding: 4% 5%;
            line-height: 12px;
            letter-spacing: 0.05em;
            img {
              height: 12px;
              width: auto;
              vertical-align: bottom;
              margin-right: 0.3em;
            }
          }
        }
        .lastCloth1:last-child {
          margin-bottom: 80%;
        }
      }
    }
  }
}
@media (min-width: 512px) {
  .productPage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    .product-container {
      position: absolute;
      width: 92%;
      left: 50%;
      transform: translateX(-50%);
      top: 10%;
      bottom: 0;
      // background-color: pink;
      .left-Aside {
        position: absolute;
        left: 0;
        width: 150px;
        height: 100%;
        overflow: auto;
        padding-top: 2%;
        box-sizing: border-box;
        background-color: #fff;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          width: 100%;
          position: relative;
          margin-bottom: 20%;
          cursor: pointer;
          &:last-child {
            margin-bottom: 100%;
          }
          .product-type {
            width: 100%;
            height: auto;
            vertical-align: bottom;

            box-sizing: border-box;
            border-radius: 10px;
          }
          p {
            line-height: 28px;
            text-align: center;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.05em;
          }
          .type-mask {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.45);
            border-radius: 10px;
          }
        }
        .activeType {
          background-color: transparent;
          .product-type {
            border: 4px solid rgb(51, 51, 51);
          }
          p {
            background-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.8) 50%,
              rgba(0, 0, 0, 0.2) 100%
            );
          }
          .type-mask {
            background-color: transparent;
          }
        }
      }
      .right-Aside {
        position: absolute;
        left: 180px;
        max-width: 1200px;
        height: 75%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
        border-radius: 10px;
        right: 0;
        overflow: auto;
        // margin-top: 2%;
        margin: 2% auto;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          width: 100%;
          position: relative;
          margin-bottom: 8%;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
          border-radius: 10px;
          padding-bottom: 0;
          box-sizing: border-box;
          overflow: hidden;
          &:last-child {
            margin-bottom: 80%;
          }
          p {
            padding: 5%;
            font-size: 12px;
            color: #333;
          }
          .cloth {
            width: 100%;
            height: auto;
          }
          .customMade {
            float: right;
            font-size: 12px;
            background-color: #3a3a3a;
            display: inline;
            border-top-left-radius: 10px;
            color: #fff;
            padding: 4% 5%;
            line-height: 12px;
            letter-spacing: 0.05em;
            cursor: pointer;
            img {
              height: 12px;
              width: auto;
              vertical-align: bottom;
              margin-right: 0.3em;
            }
          }
        }
        .lastCloth1:last-child {
          margin-bottom: 80%;
        }
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.8);
    flex-direction: column;
    .swiper-box {
      width: 100%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 15px;
      box-sizing: border-box;
      cursor: pointer;
      p {
        font-size: 20px;
        color: #333;
        text-align: left;
        padding-top: 10px;
        padding-left: 10px;
        box-sizing: border-box;
        pointer-events: none;
      }
      .cloth {
        width: 100%;
        height: auto;
        pointer-events: none;
      }
      .customMade {
        float: right;
        font-size: 16px;
        background-color: #3a3a3a;
        display: inline;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: #fff;
        padding: 4% 5%;
        line-height: 16px;
        letter-spacing: 0.05em;
        cursor: pointer;
        pointer-events: none;
        img {
          height: 16px;
          width: auto;
          vertical-align: bottom;
          margin-right: 0.3em;
          pointer-events: none;
        }
        span {
          pointer-events: none;
        }
      }
    }
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
  }
}
</style>