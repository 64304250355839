<template>
  <div style="width: 100%; height: 100%; overflow: hidden; position: absolute">
    <!-- 加载页 -->
    <div id="simple-preloader-background">
      <div id="simple-preloader-container">
        <div class="logo111">
          <img src="../assets/logo/loading.png" alt="" draggable="false" />
        </div>
        <p class="progressNum" id="preloader-text">0%</p>
        <div class="progress-box">
          <div class="myPorgress" id="myProgress"></div>
        </div>
      </div>
    </div>
    <!-- 展厅内容 -->

    <div
      class="content"
      style="width: 100%; height: 100%; overflow: hidden; position: absolute"
    >
      <div
        :id="containerId"
        style="position: absolute"
        :class="currentNav === 4 && !fullScreen ? 'productModel' : ''"
      ></div>
      <div style="display: none">
        <!-- 派发标注点点击事件 -->
        <!-- 有标注点的点击事件 -->
        <div
          v-for="index in 8"
          :key="index + 'a'"
          :class="`annoFn${index}`"
          @click="annoFn(index)"
        ></div>
        <!-- 机器点击事件 -->
        <div
          v-for="index in 16"
          :key="index + 5"
          :class="`machineFn${index}`"
          @click="machineFn(index)"
        ></div>
        <!-- 模型切换事件派发 -->
        <div class="changeModel" ref="changeModel"></div>
        <!-- 衣服材料事件派发 -->
        <div
          v-for="index in 8"
          :key="index + 200"
          :class="`clothFn${index}`"
          @click="clothFn(index)"
        ></div>
        <!-- 衣服模型事件派发 -->
        <div
          v-for="index in 22"
          :key="index + 100"
          :class="`changeCloth${index}`"
          @click="getCurrentNav(index, true)"
        ></div>
        <!-- 视频事件派发 -->
        <div
          v-for="index in 3"
          :key="index + 24"
          :class="`videoFn${index}`"
          @click="videoFn(index)"
        ></div>
        <!-- 材料模型事件派发 -->
        <!-- <div
          v-for="index in 6"
          :key="index + 'm'"
          :class="`changeMaterial${index}`"
          @click="changeMaterial(index)"
        ></div> -->
      </div>
      <!-- logo -->
      <div class="logo">
        <img src="../assets/logo/logo.png" alt="" draggable="false" />
      </div>
      <!-- 返回首页 -->
      <div class="backHome" @click="backHome()" v-show="currentNav === 4">
        <img src="../assets/home/dingzhi/back.png" alt="" />
      </div>
      <!-- 右侧列表 -->
      <ul class="settingList">
        <transition name="fade" v-for="(item, index) in settList" :key="index">
          <li
            :class="item.name"
            @click="clickSet(item.name)"
            v-show="index == 0 || otherSet"
          >
            <img
              :src="item.src"
              alt=""
              draggable="false"
              style="width: 50px; height: 50px"
            /></li
        ></transition>
      </ul>
      <!-- 底部导航 -->
      <ul class="footerNav" v-show="currentNav !== 4">
        <li
          v-for="(item, index) in footerNav"
          :key="index"
          @click="footerNavClick(index)"
        >
          <img
            :src="currentNav == index + 1 ? item.activeSrc : item.src"
            alt=""
            draggable="false"
          />
          <p :style="{ color: currentNav == index + 1 ? '#000' : '' }">
            {{ item.title }}
          </p>
        </li>
      </ul>
      <!-- 所有弹窗 -->
      <!-- 公司简介 -->
      <transition name="fade">
        <Introduction
          v-if="currentMask === 1 && maskShow"
          @closeMask="closeMask"
        ></Introduction>
      </transition>
      <!-- 公司简介结束 -->
      <!-- 合作客户 -->
      <transition name="fade">
        <Partner
          v-if="currentMask === 2 && maskShow"
          @closeMask="closeMask"
        ></Partner>
      </transition>
      <!-- 合作客户结束 -->
      <!-- 解决方案 -->
      <transition name="fade">
        <Solution
          v-if="currentMask === 3 && maskShow"
          @closeMask="closeMask"
        ></Solution>
      </transition>
      <!-- 解决方案结束 -->
      <!-- 荣誉资质 -->
      <transition name="fade">
        <Honor
          v-if="currentMask === 4 && maskShow"
          @closeMask="closeMask"
        ></Honor>
      </transition>
      <!-- 荣誉资质结束 -->
      <!-- 用户信息 -->
      <transition name="fade">
        <userInfo
          v-if="currentMask == 'showInfo' && maskShow"
          @closeMask="closeMask"
        ></userInfo>
      </transition>
      <!-- 用户信息结束 -->
      <!-- logo墙弹窗 -->
      <transition name="fade">
        <LogoWall
          v-show="currentMask === 5 && maskShow"
          ref="childLogoWall"
          @closeMask="closeMask"
        ></LogoWall>
      </transition>
      <!-- logo墙弹窗结束 -->
      <!-- 机器墙弹窗 -->
      <transition name="fade">
        <MachineWall
          v-show="currentMask === 6 && maskShow"
          ref="childMachineWall"
          :currentMachine="currentMachine"
          @closeMask="closeMask"
        ></MachineWall>
      </transition>
      <!-- 机器墙弹窗结束 -->
      <!-- 衣服弹窗 -->
      <transition name="fade">
        <ClothMask
          v-show="currentMask === 7 && maskShow"
          ref="ClothMask"
          :currentCloth="currentCloth"
          @closeMask="closeMask"
        ></ClothMask>
      </transition>
      <!-- 衣服弹窗结束 -->
      <!-- 衣服视频弹窗 -->
      <transition name="fade">
        <VideoMask
          v-if="currentMask === 8 && maskShow"
          :currentVideo="currentVideo"
          @closeMask="closeMask"
        ></VideoMask>
      </transition>
      <!-- 衣服视频弹窗 -->
      <!-- 所有弹窗结束 -->
      <!-- 产品页 -->
      <transition :name="animeName">
        <Product
          :currentNav="currentNav"
          :clothList="clothList"
          :currentType="currentType"
          @sendCurrentNav="getCurrentNav"
          @sendProductType="getProductType"
          v-if="currentNav === 2"
        ></Product>
      </transition>
      <!-- 联系我们 -->
      <transition :name="animeName">
        <Contact v-show="currentNav === 3"></Contact>
      </transition>
      <!-- 分享 -->
      <transition name="fade">
        <Share
          v-show="currentMask === 9 && maskShow"
          :currentMaterial="currentMaterial"
          :currentLines="currentLines"
          :currentColors="currentColors"
          :currenLogo="currenLogo"
          @closeMask="closeMask"
          ref="childShare"
        ></Share>
      </transition>
      <!-- 产品定制功能 -->
      <footer class="custom" v-show="currentNav === 4 && !fullScreen">
        <!-- 标题 -->
        <div class="tab-title">
          <div class="tab-title-left" id="materialTitle">
            {{ currentProduct.title }}
          </div>
          <div class="tab-title-right" id="changeLight" v-show="oldType != 5">
            <img draggable="false" src="../assets/home/dingzhi/qiehuan.png" />
            <span class="highLight">显示材料</span>
          </div>
        </div>
        <!-- 材质，纹路，，颜色 -->
        <div class="functionOne" v-show="currentTab === 1">
          <dl class="materialList">
            <dt>材质：</dt>
            <div class="materialList-box">
              <div class="materialList-container">
                <dd
                  v-for="(item, index) in currentProduct.materialList"
                  :key="index"
                  :id="`material${index + 1}`"
                  @click="changeCurrentMaterial(index + 1, item.data)"
                  :class="currentMaterial === index + 1 ? 'activeMaterial' : ''"
                  v-show="item !== '' && item.state == '1'"
                >
                  {{ item.name }}
                </dd>
              </div>
            </div>
          </dl>
          <dl class="linesList" v-show="showLinesList">
            <dt>纹路：</dt>
            <div class="linesList-box">
              <div class="linesList-container">
                <dd
                  v-for="(item, index) in currentProduct.linesList"
                  :key="index"
                  :id="`lines${index}`"
                  @click="changeLines(index + 1, item.NRMsrc, item.src)"
                  :class="currentLines === index + 1 ? 'activeLines' : ''"
                >
                  <img style="" :src="item.NRMsrc" alt="" />
                </dd>
              </div>
            </div>
          </dl>

          <dl class="colorsList">
            <dt>颜色：</dt>
            <div class="colorsList-box">
              <div class="colorList-container">
                <dd
                  v-for="(item, index) in currentProduct.colorsList"
                  :key="index"
                  :id="`colors${index}`"
                  @click="changeColor(index + 1, item)"
                  :class="currentColors === index + 1 ? 'activeColors' : ''"
                  :style="`backgroundColor:${item}`"
                ></dd>
              </div>
            </div>
          </dl>
        </div>
        <!-- logo替换 -->
        <div class="functionTwo" v-show="currentTab === 2">
          <div class="card-line">
            <div class="car-text">LOGO：</div>
            <div class="logoBox">
              <ul class="logoList">
                <li
                  class="card-item"
                  :id="`mylogo${index}`"
                  v-for="(item, index) in currentProduct.logoList"
                  :key="index"
                  @click="clickLogo(item, index)"
                  :class="currenLogo === index + 1 ? 'activeLogo' : ''"
                >
                  <img
                    draggable="false"
                    :src="item"
                    :class="`logo${index}`"
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="card-line" style="margin-top: 10px">
            <div class="car-text">缩　放：</div>
            <div class="progress" id="progressScale">
              <div class="progress-left"></div>
              <div class="progress-center"></div>
              <div class="circleProgress"></div>
            </div>
          </div>
        </div>
        <!-- 产品选择 -->
        <div class="productSelect-box">
          <ul class="productSelect" v-show="currentTab === 3">
            <li
              v-for="(item, index) in selectList"
              :key="index"
              @click="productSelect(index)"
              :class="currentType === index + 1 ? 'activeSelect' : ''"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="functionThree" v-show="currentTab === 3">
          <ul class="productList">
            <li
              v-for="(item, index) in clothList"
              :key="index"
              v-show="item.type == currentType"
              @click="getCurrentNav(item.id)"
              :class="
                currentProduct.productId === item.productId
                  ? 'activeProduct'
                  : ''
              "
            >
              <img :src="item.src" alt="" />
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <!-- 底部功能切换导航 -->
        <ul class="functionChange">
          <li
            v-for="(item, index) in tabitems"
            :key="index"
            @click="currentTab = index + 1"
            :class="currentTab === index + 1 ? 'activeTab' : ''"
            v-show="parseInt(tabShow / Math.pow(2, index)) % 2"
          >
            <img :src="item.src" alt="" />
            <p>{{ item.text }}</p>
          </li>
        </ul>
      </footer>
      <!-- 底部信息 -->
      <div class="power" v-show="currentNav !== 4">
        所有素材、资料、商标等版权，均属于盈通公司所有，如发现抄袭、盗用等，盈通保持追究法律责任的权利
      </div>
    </div>
    <!-- 背景音乐 -->
    <audio
      style="display: none; height: 0"
      id="bg-music"
      loop="loop"
      src="../assets/video/music.mp3"
    ></audio>
  </div>
</template>

<script>
import * as v3dAppAPI from "../v3dApp/model.js";
// 公司简介
import Introduction from "@/components/mask/introduction.vue";
// 合作客户
import Partner from "@/components/mask/partner.vue";
// 解决方案
import Solution from "@/components/mask/solution.vue";
// 荣誉墙
import Honor from "@/components/mask/honor.vue";
// logo墙
import LogoWall from "@/components/mask/logoWall.vue";
// 机器墙
import MachineWall from "@/components/mask/machineWall.vue";
// 衣服弹窗
import ClothMask from "@/components/mask/clothMask.vue";
// 视频弹窗
import VideoMask from "@/components/mask/videoMask.vue";
// 产品类别
import Product from "@/components/product.vue";
// 联系我们
import Contact from "@/components/contact.vue";
// 分享弹窗
import Share from "@/components/mask/share.vue";
// 用户信息弹窗
import userInfo from "@/components/mask/userInfo.vue";

export default {
  name: "V3DApp",
  components: {
    // Anno,
    Product,
    Contact,
    ClothMask,
    Introduction,
    Partner,
    Solution,
    Honor,
    LogoWall,
    MachineWall,
    VideoMask,
    Share,
    userInfo,
  },
  data() {
    return {
      containerId: v3dAppAPI.CONTAINER_ID,
      // 右侧列表
      settList: [
        {
          src: require("../assets/home/tool1.png"),
          name: "set",
        },
        {
          src: require("../assets/home/tool2.png"),
          name: "rotate",
        },
        {
          src: require("../assets/home/tool3.png"),
          name: "share",
        },
        {
          src: require("../assets/home/tool4.png"),
          name: "music",
        },
        {
          src: require("../assets/home/tool6.png"),
          name: "userInfo",
        },
      ],

      // 当前列表,
      currentList: 1,
      otherSet: true,
      // 底部导航
      footerNav: [
        {
          src: require("../assets/home/tabbar/index.png"),
          activeSrc: require("../assets/home/tabbar/indexActive.png"),
          title: "首页",
        },
        {
          src: require("../assets/home/tabbar/product.png"),
          activeSrc: require("../assets/home/tabbar/productActive.png"),
          title: "产品",
        },
        {
          src: require("../assets/home/tabbar/contact.png"),
          activeSrc: require("../assets/home/tabbar/contactActive.png"),
          title: "联系我们",
        },
      ],
      // 当前导航
      currentNav: 1,
      // 当前弹窗
      currentMask: 0,
      maskShow: false,
      // 当前机器图片
      currentMachine: 1,
      // 功能列表
      tabitems: [
        {
          src: require("../assets/home/dingzhi/dingzhi.png"),
          text: "个性化定制",
        },
        {
          src: require("../assets/home/dingzhi/tihuan.png"),
          text: "LOGO替换",
        },
        {
          src: require("../assets/home/dingzhi/xuanze.png"),
          text: "产品选择",
        },
      ],
      currentTab: 1,
      // 材质、当前材质
      materialList: ["", "哑光", "高光", "布面"],
      currentMaterial: 1,
      // 纹路、当前纹路
      currentLines: 1,
      showLinesList: true,

      // 当前颜色
      currentColors: 1,
      // logo列表
      logoList: [
        require("../assets/logo/LOGO-1.png"),
        require("../assets/logo/LOGO-2.png"),
        require("../assets/logo/LOGO-3.png"),
        require("../assets/logo/LOGO-4.png"),
        require("../assets/logo/LOGO-5.png"),
        require("../assets/logo/LOGO-6.png"),
        require("../assets/logo/LOGO-7.png"),
        require("../assets/logo/LOGO-8.png"),
      ],
      currenLogo: 0,
      // 产品选择

      clothList: [],
      tabShow: 7,
      currentType: 1,
      oldType: 1,
      // 当前产品
      currentProduct: 1,

      fullScreen: false,
      // 当前类型
      selectList: [
        "户外休闲运动应用",
        "商务服装应用",
        "军工劳保应用",
        "鞋材/内衣应用",
        "盈通产品应用",
      ],
      // 衣服标注点图片
      currentCloth: 1,
      // 视频
      currentVideo: 1,
      atHome: true,
      // 音频
      firstPlay: true,
      musicState: false,
      animeName: "fade",
    };
  },
  app: null,
  methods: {
    takeScreenImage(callback) {
      let _this = this;
      // 获取屏幕宽度高度
      let screenWidth = document.body.clientWidth;
      let screenHeigth = document.body.clientHeight;
      let screenshotWidth;
      let screenshotHeight;
      if (screenWidth / 0.7 > screenHeigth) {
        // 以高度截图
        screenshotWidth = screenHeigth * 0.7;
        screenshotHeight = screenHeigth;
      } else {
        // 以宽度截图
        screenshotHeight = screenWidth / 0.7;
        screenshotWidth = screenWidth;
      }
      let startX = (screenWidth - screenshotWidth) / 2;
      let startY = (screenHeigth - screenshotHeight) / 2;
      let nowCanvas = document.createElement("canvas");
      nowCanvas.width = screenshotWidth;
      nowCanvas.height = screenshotHeight;
      let nowContext = nowCanvas.getContext("2d");
      let image = new Image();
      image.src = document.querySelector(".v3d-canvas").toDataURL("image/png");
      image.onload = function () {
        //清理画布，便于重新绘制
        _this.imgCut(
          nowContext,
          image,
          screenWidth,
          screenHeigth,
          startX,
          startY,
          screenshotWidth,
          screenshotHeight
        );
        if (callback) callback(nowCanvas, image);
      };
    },
    imgCut(context, image, imgElementW, imgElementH, sx, sy, w, h) {
      //清理画布，便于重新绘制
      context.clearRect(0, 0, imgElementW, imgElementH);
      //计算 ：比例 = 原图像/显示图像
      var ratioW = image.width / imgElementW;
      var ratioH = image.height / imgElementH;
      //根据截取图像的所占位置及大小计算出在原图所占的位置及大小
      //.drawImage(图像对象,原图像截取的起始X坐标,原图像截取的起始Y坐标,原图像截取的宽度,原图像截取的高度，
      // 绘制图像的起始X坐标,绘制图像的起始Y坐标,绘制图像所需要的宽度,绘制图像所需要的高度);
      context.drawImage(
        image,
        ratioW * sx,
        ratioH * sy,
        ratioW * w,
        ratioH * h,
        0,
        0,
        w,
        h
      );
    },
    // 点击右上按钮事件
    clickSet(item) {
      let _this = this;
      if (item === "set") {
        this.otherSet = !this.otherSet;
      } else if (item === "share") {
        // 截屏
        this.takeScreenImage(function (nowCanvas, image) {
          // v3d.puzzles.procedures.takeScreen();
          _this.currentMask = 9;
          _this.maskShow = true;
          _this.$refs.childShare.createQrCode(nowCanvas.toDataURL("image/png"));
        });
      } else if (item === "userInfo") {
        // 个人信息
        _this.currentMask = "showInfo";
        _this.maskShow = true;
      } else if (item === "music") {
        // 播放音乐
        let audio = document.getElementById("bg-music");
        if (this.musicState) {
          document.querySelector(".music").style.filter = "";
          audio.pause();
        } else {
          document.querySelector(".music").style.filter = "invert(1)";
          audio.play();
        }
        this.musicState = !this.musicState;
      }
    },
    // 点击标注点事件
    annoFn(index) {
      if (index >= 5 && index <= 8) {
        this.currentMask = 5;

        this.$refs.childLogoWall.showContent(index);
      } else {
        this.currentMask = index;
      }
      this.maskShow = true;
    },
    machineFn(index) {
      this.currentMask = 6;
      this.maskShow = true;
      this.currentMachine = index;
      this.$refs.childMachineWall.showInfo(index);
    },
    //  衣服弹窗
    clothFn(index) {
      this.currentMask = 7;
      this.$refs.ClothMask.showDetails(index);
      this.maskShow = true;
      this.currentCloth = index;
    },
    // 视频弹窗
    videoFn(index) {
      this.currentMask = 8;
      this.maskShow = true;
      this.currentVideo = index;
    },
    // 关闭弹窗事件
    closeMask() {
      if (this.currentMask === 7) {
        if (
          this.$refs.ClothMask.$refs.clothVideo &&
          !this.$refs.ClothMask.$refs.clothVideo.paused
        ) {
          this.$refs.ClothMask.$refs.clothVideo.pause();
        }
      }
      // console.log(this.currentMask)
      this.currentMask = 0;
      this.maskShow = false;
    },
    footerNavClick(index) {
      this.currentNav = index + 1;
      if (this.$route.query.product !== "scene" && this.currentNav === 1) {
        this.$router.push({ name: "home", query: { product: "scene" } });
        this.$refs.changeModel.click();
      }
    },
    // 更改当前产品类型
    getProductType(val) {
      this.currentType = val;
    },
    productSelect(index) {
   
      this.currentType = index + 1;
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    //更改当前导航
    getCurrentNav(id, atHome = true) {
      var _this = this;
      this.atHome = atHome ? true : false;
      this.currentNav = 4;

      _this.currentProduct = _this.clothList[id - 1];

      var phoneType = "app";
      if (!this._isMobile()) {
        phoneType = "pc";
      }
      this.$axios
        .post(
          _this.$global.serverSrc +
            "/api/get/product/details?productId=" +
            _this.currentProduct.productId +
            "&visitType=" +
            phoneType,
          {}
        )
        .then((data) => {});

      _this.currentType = _this.currentProduct.type;
      _this.oldType = _this.currentType;

      _this.$router.push({
        name: "home",
        query: { product: id },
      });
      _this.$refs.changeModel.click();

      _this.initUi();

      // this.currentProduct = this.getModelId(val, this.clothList);
    },
    // 初始化选中项
    init() {
      // 默认选中的材质、纹路、颜色
      this.currentMaterial = 1;

      this.currentLines = 1;
      this.currentColors = 1;
      // 默认选中的logo、大小
      this.currenLogo = 0;

      document.getElementsByClassName("progress-center")[0].style.width = "50%";
      document.getElementsByClassName("circleProgress")[0].style.left = "50%";
    },
    // 初始化ui页面（定制页面）
    initUi(modelType) {
      this.init();
      var _this = this;
      // 初始化界面
      this.tabShow = this.currentProduct.tabShow;

      if (
        _this.currentProduct.colorsList.length == 0 &&
        _this.currentProduct.linesList.length == 0 &&
        (_this.currentProduct.logoList.length == 0 ||
          !_this.currentProduct.logoList)
      ) {
        this.tabShow = 4;
      }

      if (
        _this.currentProduct.colorsList.length == 0 &&
        _this.currentProduct.linesList.length == 0 &&
        _this.currentProduct.logoList.length > 0
      ) {
        this.tabShow = 6;
      }
      if (
        (_this.currentProduct.colorsList.length > 0 ||
          _this.currentProduct.linesList.length > 0) &&
        (_this.currentProduct.logoList.length == 0 ||
          !_this.currentProduct.logoList)
      ) {
        this.tabShow = 5;
      }

      if (this.currentProduct) {
        if (
          !this.currentProduct.materialList[0].state &&
          !this.currentProduct.materialList[1].state &&
          this.currentProduct.materialList[2].state == "1"
        ) {
          this.currentMaterial = 3;
          _this.changeCurrentMaterial(
            _this.currentMaterial,
            _this.currentProduct.materialList[_this.currentMaterial - 1].data
          );
        } else {
          this.currentMaterial = 1;
          // console.log(_this.currentProduct.materialList[_this.currentMaterial - 1].data)
          _this.changeCurrentMaterial(
            _this.currentMaterial,
            _this.currentProduct.materialList[_this.currentMaterial - 1].data
          );
        }
      }

      // 定制变化
      // 如果没有纹路列表
      if (!this.currentProduct.linesList) {
        this.showLinesList = false;
      }
      var _this = this;
      if (this.tabShow === 0) {
        this.fullScreen = true;
      } else {
        this.fullScreen = false;
      }

      // 底部选中状态变化
      for (let i = 0; i < 3; i++) {
        if (parseInt(this.tabShow / Math.pow(2, i)) % 2 == 1) {
          this.currentTab = i + 1;
          break;
        }
      }
    },
    // 返回首页
    backHome() {
       localStorage.removeItem('shareJson')
      if (this.atHome) {
        this.currentNav = 1;
        this.$router.push({ name: "home", query: { product: "scene" } });
        this.$refs.changeModel.click();
      } else {
        this.currentNav = 2;
      }
    },
    //切换logo
    clickLogo(url, index) {
      this.currenLogo = index + 1;
      v3dAppAPI.ClickLogo(url);
    },
    // 获取当前模型id
    getModelId(index, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == index) {
          return list[i];
        }
      }
    },

    // 更改当前材质
    changeCurrentMaterial(index, data) {
      this.currentMaterial = index;
      v3d.puzzles.procedures.materialClick(data);
      // 是否展示纹路
      this.showLinesList = data[0] === 1;
    },
    // 更改模型产品颜色
    changeColor(index, color) {
      this.currentColors = index;
      v3d.puzzles.procedures.clickColor(color);
    },
    // 更改模型产品纹路
    changeLines(index, lines) {
      this.currentLines = index;
      v3d.puzzles.procedures.clickLine(lines, "YH");
    },
    getData() {
      //clothList
      var _this = this;
      var logoset = 0;
      this.$axios
        .post(_this.$global.serverSrc + "/api/get/product/list?userid="+this.$cookies.get("userid"), {})
        .then((data) => {
          data.data.result.forEach((item) => {
            if (item.appJson != null && item.modelName != null) {
              var data = JSON.parse(item.appJson);
              data.productId = item.id;
              data.type = item.typeId;
              data.id = item.modelName.split("_")[1];
              data.src = item.icon;
              data.title = item.materialIdsName
              this.clothList[item.modelName.split("_")[1] - 1] = data;
              localStorage.setItem("clothList", JSON.stringify(this.clothList));
            }
            /*  this.logoList = item.logoList */
            /* if (item.logoJson != null) {
              if (logoset == 0) {
                this.logoList = JSON.parse(item.logoJson);
                logoset = 1;
              }
            }  */
          });
        });
    },
  },
  created() {},
  watch: {
    currentNav(val, oldVal) {
      if ((val == 2 && oldVal == 3) || (val == 3 && oldVal == 2)) {
        this.animeName = "none";
      } else {
        this.animeName = "fade";
      }
     
      this.$store.commit("updateNav", val);

      if (val != 1) {
        // 暂停音乐
        document.querySelector("#bg-music").pause();

        if (val == 4) {
          document.querySelector(".music").style.filter = "";
          this.settList = this.settList.filter((item) => item.name != "music");
        }
      } else {
        // 判断settList是否存在music图标
        if (this.settList.filter((item) => item.name == "music").length == 0) {
          this.settList.splice(this.settList.length - 1, 0, {
            src: require("../assets/home/tool4.png"),
            name: "music",
          });
          if (this.musicState) {
            setTimeout(() => {
              document.querySelector(".music").style.filter = "invert(1)";
            }, 0);
          }
        }
        if (this.musicState && val == 1) {
          document.querySelector("#bg-music").play();
        }
      }
    },
    currentMask(val) {
      this.$store.commit("updateMask", val);
      if (val == 8) {
        // 暂停音乐
        document.querySelector("#bg-music").pause();
      } else {
        if (this.musicState && this.currentNav == 1) {
          document.querySelector("#bg-music").play();
        }
      }
    },
  },
  mounted() {
    let _this = this;
    // 音乐播放
    let audio = document.getElementById("bg-music");

    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          // wx 音乐播放
          _this.clickSet("music");
        },
        false
      );
      _this.firstPlay = false;
    }
    document.querySelector("html").addEventListener("touchend", function () {
      if (_this.firstPlay && _this.currentNav == 1) {
        _this.clickSet("music");
      }
      _this.firstPlay = false;
    });
    document.querySelector("html").addEventListener("mousedown", function () {
      if (_this.firstPlay && _this.currentNav == 1) {
        _this.clickSet("music");
      }
      _this.firstPlay = false;
    });

    //
    v3dAppAPI.createApp().then((app) => {
      this.$options.app = app;
    });
    var logoset = 0;
    this.getData();
    // 加载完毕

    v3d._customEvents = new v3d.EventDispatcher();
    v3d._customEvents.addEventListener("onload", function (event) {
      let productModel = _this.$route.query.product;

      if (!(productModel === "scene" || !productModel)) {
        _this.currentNav = 4;
        _this.$store.commit("updateNav", _this.currentNav);
        // 在单独模型页
        productModel = parseInt(productModel);
        _this.clothList = JSON.parse(localStorage.getItem("clothList"));

        if(localStorage.getItem('shareJson')){
           _this.currentProduct = JSON.parse(JSON.parse(localStorage.getItem('shareJson')).appJson) 
            _this.currentProduct.title = JSON.parse(localStorage.getItem('shareJson')).materialIdsName
          
        }else{
          _this.currentProduct = _this.getModelId(productModel, _this.clothList);
        }
       

        _this.currentType = _this.currentProduct.type;
        _this.oldType = _this.currentType;
        _this.initUi();

        // 获取当前材质、纹路、颜色参数
        let materialNum = parseInt(_this.$route.query.material);
        if (_this.currentProduct.materialList) {
          if (materialNum) {
            _this.changeCurrentMaterial(
              materialNum,
              _this.currentProduct.materialList[materialNum - 1].data
            );
          } else {
            _this.changeCurrentMaterial(
              _this.currentMaterial,
              _this.currentProduct.materialList[_this.currentMaterial - 1].data
            );
          }
        }

        if (
          _this.currentProduct.colorsList.length == 0 &&
          _this.currentProduct.linesList.length == 0 &&
          _this.currentProduct.logoList.length == 0
        ) {
          _this.currentTab = 3;
        }

        let linesNum = parseInt(_this.$route.query.lines);
        if (_this.currentProduct.linesList && linesNum) {
          _this.changeLines(
            linesNum,
            _this.currentProduct.linesList[linesNum - 1].NRMsrc
          );
        }
        let colorNum = parseInt(_this.$route.query.color);
        if (colorNum !== 1 && colorNum) {
          _this.changeColor(
            colorNum,
            _this.currentProduct.colorsList[colorNum - 1]
          );
        }
        // 是否开启衣服
        let openCloth = Boolean(_this.$route.query.openCloth === "true");
        if (openCloth) {
          v3d.puzzles.procedures.openCloth();
        }
        // 当前logo图标
        let logoNum = parseInt(_this.$route.query.logo);
        if (logoNum && logoNum !== 0) {
          document.querySelector(`#mylogo${logoNum - 1}`).click();
        }
        // logo缩放
        let logoScale = parseInt(_this.$route.query.logoScale);
        if (logoScale && logoScale !== 0) {
          v3d.puzzles.procedures.changeLogoScale(logoScale);
        }
        // 是否高亮
        let lightHight = Boolean(_this.$route.query.lightHight === "true");
        if (lightHight || !_this.$route.query.lightHight) {
          v3d.puzzles.procedures.startAnime();
        } else {
          v3d.puzzles.procedures.stopAnime();
        }
      }
    });
  },
  beforeDestroy() {
    if (this.$options.app) {
      this.$options.app.dispose();
      this.$options.app = null;
    }
  },
};
</script>

<style lang="less" >
@import "../v3dApp/model.css";
@keyframes leftRotate{
  from{
    transform:  rotate(0deg);
  }
  to{
    transform:  rotate(360deg);
  }
}
@keyframes rightRotate{
  from{
    transform:rotate(0deg);
  }
  to{
    transform:  rotate(-360deg);
  }
}


.logo {
  width: 36%;
  position: absolute;
  left: 4%;
  top: 2%;
  max-width: 210px;
  img {
    width: 100%;
    height: auto;
  }
}
.backHome {
  width: 15%;
  position: absolute;
  right: 3.8%;
  top: 2%;
  max-width: 50px;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}
.settingList {
  width: 15%;
  position: absolute;
  right: 3.8%;
  top: 10.3%;
  max-width: 50px;
  li {
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.footerNav {
  width: 92%;
  max-width: 450px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5.5%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  border-radius: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
  li {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    color: #acafb5;
    padding: 3% 0 3.2%;
    cursor: pointer;
    img {
      width: 35%;
      margin-bottom: 4px;
    }
  }
}
.pc {
  display: none;
}

.mask {
  background-color: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  .maskBox {
    width: 92%;
    height: 95%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 25px;
    padding: 3.8% 6.8%;
    box-sizing: border-box;
    .mask-logo {
      width: 39%;
      max-width: 150px;
      margin-bottom: 3.5%;
      img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }
    .mask-close {
      width: 4%;
      max-width: 25px;
      position: absolute;
      top: 1.5%;
      right: 3%;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }
    .mask-container {
      width: 100%;
      height: 90%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .videoResource {
        width: 100%;
        margin-bottom: 3.5%;
        video {
          width: 100%;
          height: auto;
          border-radius: 15px;
        }
        img{
          width: 100%;
          height: auto;
          border-radius: 15px;
        }
      }
      h4 {
        font-size: 14px;
        color: #92d050;
        font-weight: 700;
        border-bottom: 2px solid #92d050;
        padding-bottom: 1.1%;
        margin-left: 0.5%;
        box-sizing: border-box;
        margin-bottom: 2%;
        letter-spacing: 0.1em;
      }
      .circle {
        p {
          font-size: 12px;
          margin-bottom: 5%;
          color: #333;
        }
      }
      .profile {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.partner {
  .mask-container {
    h4 {
      margin-top: 8%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
.solution,
.honor {
  .mask-container {
    h4 {
      margin-top: 4%;
    }
    img {
      width: 100%;
      height: auto;
      margin-top: 3%;
    }
  }
}
.logoWall {
  .wallBox {
    width: 92%;
    max-width: 650px;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // background-color: #000;
    box-sizing: border-box;
    // border-radius: 10px;
    // overflow: auto;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    .mask-close {
      width: 8%;
      max-width: 35px;
      filter: invert(100%);
      position: absolute;
      right: 0;
      bottom: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
        border: 1px solid #000;
        box-sizing: border-box;
        border-radius: 50%;
        padding: 3px;
      }
    }
    .boxSize {
      width: 100%;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .logoImg {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.videoMask {
  .mask-close {
    width: 100%;
    max-width: 35px;
    filter: invert(100%);
    position: absolute;
    z-index: 2;
    right: 0%;
    padding-bottom: 30%;
    bottom: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
      border: 1px solid #000;
      box-sizing: border-box;
      border-radius: 50%;
      padding: 3px;
    }
  }
  .videoBox {
    width: 80%;
    // height: 85%;
    max-width: 1080px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    video {
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
    }
  }
}
@media (max-width: 512px) {
  .machineWall {
    .machineBox {
      width: 92%;
      height: 85%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      background-color: #fff;
      .mask-close {
        width: 6%;
        max-width: 35px;
        position: absolute;
        bottom: 101%;
        right: 0;
        cursor: pointer;
        img {
          border: 1px solid #fff;
          background-color: #fff;
          border-radius: 50%;
          box-sizing: border-box;
          padding: 2px;
          width: 100%;
          max-width: 100%;
          max-height: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
      .machineImg {
        width: 100%;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        img {
          height: auto;
          width: 100%;
          background-color: #fff;
  
        }
      }
    }
  }
}
// 媒体查询
@media (min-width: 512px) {
  .phone {
    display: none;
  }
  .pc {
    display: block;
  }
  .mask {
    .maskBox {
      width: 60%;
      max-width: 650px;
      height: 80%;
      padding: 2.2% 3.2%;
      .mask-logo {
        width: 39%;
        max-width: 150px;
        margin-bottom: 3.5%;
        img {
          width: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
      .mask-close {
        width: 4%;
        max-width: 25px;
        position: absolute;
        top: -8%;
        right: -10%;
        // background-color: #fff;
        // border-radius: 50%;
        // padding: 10px;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
          vertical-align: bottom;
          background-color: #fff;
          border-radius: 50%;
          padding: 10px;
        }
      }
      .mask-container {
        h4 {
          font-size: 16px;
        }
      }
    }
  }
  .partner {
    .mask-container {
      h4 {
        margin-top: 4%;
      }
      img {
        width: 100%;
        height: auto;
        margin-bottom: 6%;
      }
    }
  }
  .machineWall {
    .machineBox {
      width: 92%;
      height: 82%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      // padding: 10px;
      box-sizing: border-box;
      // background-color: #000;
      .mask-close {
        width: 6%;
        max-width: 35px;
        position: absolute;
        bottom: 101%;
        right: 0;
        cursor: pointer;
        img {
          border: 1px solid #fff;
          background-color: #fff;
          border-radius: 50%;
          box-sizing: border-box;
          padding: 2px;
          width: 100%;
          max-width: 100%;
          max-height: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
      .machineImg {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        &::-webkit-scrollbar {
          display: none;
        }
        img {
          max-height: 100%;
          max-width: 100%;
          background-color: #fff;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 15px;
          
        }
      }
    }
  }
}
// 标注点

.anno(@count) when (@count > 0) {
  .anno(@count - 1);
  .anno@{count} {
    position: absolute;
    width: 6vw;
    max-width: 30px;
    // pointer-events: all;
    animation: shineAnime 2s linear infinite;
    img {
      width: 100%;
      height: auto;
    }
  }
  .clothAnno@{count},.modelAnno@{count}{
    position: absolute;
    width: 100%;
    // max-width: 30px;
    // pointer-events: all;
    // animation: shineAnime 2s linear infinite;
      // border: 1px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
      border-radius: 50%;
      line-height: 0;
    &>img {
      width: 100%;
      height: auto;
      vertical-align: top;
      display: flex;
      align-items: center;
      justify-content: center;
      // transform: scale(0.8);
      background-color: #2a423c;
      border-radius: 50%;
    }
    .outsideCircle{
      position: absolute;
      width: 145%;
      height: 145%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      img{
        width: 100%;
        height: 100%;
        animation: leftRotate 2s linear infinite;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
      }
    }
    .insideCircle{
      position: absolute;
      width: 140%;
      height: 140%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      img{
        width: 100%;
        height: 100%;
        animation: rightRotate 2s linear infinite;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
      }
    }
  }
  #cloth@{count}{
     position: absolute;
    top: 0px;
    left: 0px;
    width: 6vw;
    // max-width: 30px;
    height: auto;
    padding: 0;
    border: 0px solid transparent;
    border-radius: 0;
    font-size: 36px;
    font-family: sans-serif;
    line-height: 26px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: transparent;
    cursor: pointer;
    color: #dfb07a;
  }
}
.clothAnno {
  position: absolute;
  width: 6vw;
  max-width: 20px;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  img {

    width: 100%;
    height: auto;
    vertical-align: top;
  }
}
.clothAnnoName{
    position: absolute;
    white-space: nowrap;
    font-size: 14px;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    color: #3a3a3a;
    line-height: 14px;
  }
  .E1-hasMask111{
     left: -32px;
     transform: translate(-50%,-50%);
    top: 50%;
  }
  .E3-hasMask33,.E1-hasMask17,.E3-hasMask37,.E2-hasMask27,.E1-hasMask19,.E3-hasMask35,.E3-hasMask36,.E8-hasMask813 {
    color: #fafafa;
  }
  

@keyframes shineAnime {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.anno(22);
</style>

<style lang="less">
@media (max-width: 512px) {
  .productModel {
    top: -17% !important;
  }
  .custom {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .tab-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 4%;
      padding: 1.5% 0;
      border-bottom: 1px solid #ededed;
      color: #3a3a3a;
      font-size: 14px;
      font-weight: bold;
    }
    .tab-title-right {
      background-color: #3e3e3e;
      color: #fafafa;
      padding: 8px 12px;
      border-radius: 20px;
      font-size: 12px;
      /* transform: scale(0.8); */
      line-height: 12px;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        vertical-align: bottom;
        padding-right: 3px;
      }
    }
    .functionOne {
      width: 92%;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin: 3% 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      padding: 1% 3%;
      box-sizing: border-box;
      font-size: 12px;

      .materialList,
      .colorsList,
      .linesList {
        width: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .materialList-box,
        .colorsList-box,
        .linesList-box {
          position: relative;
          width: 84%;
          height: 38px;
          display: flex;
          align-items: center;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .materialList-container,
          .colorList-container,
          .linesList-container {
            position: absolute;
            display: flex;
          }
        }
        .activeMaterial {
          background-color: #3e3e3e;
          color: #ffffff;
        }
        .activeColors {
          border: 1px solid #000;
          border-radius: 6px;
        }
        .activeLines {
          border: 1px solid #000;
          border-radius: 6px;
        }

        dd {
          width: 45px;
          height: 30px;
          border-radius: 6px;
          text-align: center;
          line-height: 30px;
          // margin: 1% 1%;
          background-color: #f0f0f0;
          color: #3e3e3e;
          transform: scale(0.8);
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: center;
          border: 1px solid rgba(0, 0, 0, 0.2);
          cursor: pointer;
          border-radius: 6px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            filter: grayscale(1);
          }
        }
      }
    }
    .functionTwo {
      width: 92%;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin: 3% 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      padding: 2% 3% 3%;
      box-sizing: border-box;
      font-size: 12px;
      .card-line {
        display: flex;
        align-items: center;
        font-size: 14px;
        .progress {
          margin: 3px 8px;
          display: flex;
          align-items: center;
          flex: 1;
          position: relative;
          .progress-left {
            position: absolute;
            width: 100%;
            height: 6px;
            border-radius: 20px;
            background-color: #e8e7eb;
          }
          .progress-center {
            position: absolute;
            width: 50%;
            height: 6px;
            border-radius: 50%;
            border: 1px solid #3e3e3e;
            background-color: #000;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          .circleProgress {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            transform: translateY(-50%);
            left: 50%;
            border: 1px solid #000;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        .logoBox {
          position: relative;
          width: 82%;
          height: 38px;
          overflow-x: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .logoList {
            position: absolute;
            display: flex;
          }
        }
      }
      .car-text {
        margin-left: 5px;
        font-weight: bold;
        color: #3a3a3a;
        font-size: 12px;
        white-space: nowrap;
      }
      .card-item {
        width: 45px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin: 1% 1%;
        background-color: #f0f0f0;
        color: #3e3e3e;
        transform: scale(0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid transparent;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
        }
      }
      .activeLogo {
        border: 1px solid #000;
        border-radius: 6px;
        // box-shadow: 0 0 20px #ccc;
      }
    }
    .functionThree {
      width: 92%;
      height: 20vw;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      box-sizing: border-box;
      font-size: 12px;
      overflow: auto;
      margin: 3% 0;
      &::-webkit-scrollbar {
        display: none;
      }
      .productList {
        height: 100%;
        position: absolute;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        padding: 5px;
        box-sizing: border-box;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .activeProduct {
          background-color: #525252;
          p {
            background-color: rgba(187, 187, 190, 0.8);
            color: #000;
          }
        }
        li {
          min-width: calc(25% - 4px);
          margin-right: 5px;
          height: 100%;
          text-align: center;
          background-color: #ededf0;
          box-sizing: border-box;
          border-radius: 15px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            max-width: 100%;
            max-height: 100%;
          }
          p {
            font-size: 12px;
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.45);
            line-height: 26px;
            color: #fff;
          }
        }
      }
    }
    .productSelect-box {
      width: 100%;
      position: absolute;
      bottom: 102%;
      left: 50%;
      transform: translateX(-50%) scale(0.9);
      font-size: 12px;
      color: #fff;
      .productSelect {
        position: absolute;
        width: 100%;
        display: flex;
        // justify-content: start;
        align-items: center;
        bottom: 100%;
        left: 0%;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .activeSelect {
          color: #fff;
          background-color: rgba(58, 58, 58);
        }
        li {
          min-width: 100px;
          transform: scale(0.8);
          background: #fff;
          color: #000;
          border: 1px solid #000;
          text-align: center;
          padding: 10px 0;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .functionChange {
      position: relative;
      width: 92%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: space-around;
      margin-bottom: 4%;
      .activeTab {
        background-color: rgba(0, 0, 0, 0.2);
      }
      li {
        width: 19vw;
        max-width: 70px;
        height: 19vw;
        max-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 30%;
          height: auto;
          margin-bottom: 5px;
        }
        p {
          transform: scale(0.8);
          white-space: nowrap;
        }
      }
    }
  }
}
@media (min-width: 512px) {
  .productModel {
    left: 17% !important;
    // transform: scale(1.1);
  }
  .custom {
    width: 33%;
    max-width: 520px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 15px;

    .tab-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 4%;
      padding: 1.5% 0;
      border-bottom: 1px solid #ededed;
      color: #3a3a3a;
      font-size: 14px;
      font-weight: bold;
    }
    .tab-title-right {
      background-color: #3e3e3e;
      color: #fafafa;
      padding: 8px 12px;
      border-radius: 20px;
      font-size: 12px;
      /* transform: scale(0.8); */
      line-height: 12px;
      cursor: pointer;
      img {
        width: auto;
        height: 9px;
        vertical-align: baseline;
        padding-right: 5px;
      }
    }
    .functionOne {
      width: 92%;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin: 3% 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      padding: 1% 3%;
      box-sizing: border-box;
      font-size: 12px;
      overflow: hidden;
      .materialList,
      .colorsList {
        width: 100%;
        align-items: center;
        white-space: nowrap;
        clear: both;
        dt {
          padding: 10px;
          border-bottom: 1px solid #ededed;
          box-sizing: border-box;
        }
        .activeMaterial {
          background-color: #3e3e3e;
          color: #ffffff;
          // box-shadow: 0 0 20px #ccc;
        }
        .activeColors {
          border: 1px solid #000;
          border-radius: 6px;
          // box-shadow: 0 0 20px #ccc;
        }
        dd {
          width: 45px;
          height: 30px;
          border-radius: 6px;
          text-align: center;
          line-height: 30px;
          margin: 1% 1%;
          background-color: #f0f0f0;
          color: #3e3e3e;
          transform: scale(0.8);
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: center;
          border: 1px solid rgba(0, 0, 0, 0.2);
          float: left;
          cursor: pointer;
        }
      }
      .linesList {
        width: 100%;
        align-items: center;
        white-space: nowrap;
        clear: both;
        dt {
          padding: 10px;
          border-bottom: 1px solid #ededed;
          box-sizing: border-box;
        }
        .activeLines {
          border: 1px solid #000;
          border-radius: 6px;
          // box-shadow: 0 0 20px #ccc;
        }

        dd {
          width: 45px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          margin: 1% 1%;
          background-color: #f0f0f0;
          color: #3e3e3e;
          transform: scale(0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border: 1px solid transparent;
          float: left;
          cursor: pointer;
          overflow: hidden;
          border-radius: 6px;
          img {
            width: 100%;
            height: auto;
            filter: grayscale(1);
          }
        }
      }
    }
    .functionTwo {
      width: 92%;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin: 3% 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      padding: 2% 3% 3%;
      box-sizing: border-box;
      font-size: 12px;
      .card-line {
        font-size: 14px;
        clear: both;
        .progress {
          padding: 3px 8px;
          width: 94%;
          display: flex;
          align-items: center;
          flex: 1;
          position: relative;
          box-sizing: border-box;
          margin: 12px 0;

          .progress-left {
            position: absolute;

            width: 100%;
            height: 6px;
            border-radius: 20px;
            background-color: #e8e7eb;
          }
          .progress-center {
            position: absolute;
            width: 50%;
            height: 6px;
            border-radius: 50%;
            border: 1px solid #3e3e3e;
            background-color: #000;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          .circleProgress {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            transform: translateY(-50%);
            left: 50%;
            border: 1px solid #000;
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
      .car-text {
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #ededed;
        font-weight: bold;
        color: #3a3a3a;
        font-size: 12px;
        white-space: nowrap;
      }
      .card-item {
        width: 31%;
        height: auto;
        text-align: center;
        line-height: 30px;
        margin: 1% 1%;
        background-color: #f0f0f0;
        color: #3e3e3e;
        transform: scale(0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid transparent;
        float: left;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
        }
      }
      .activeLogo {
        border: 1px solid #000;
        border-radius: 6px;
        // box-shadow: 0 0 20px #ccc;
      }
    }
    .functionThree {
      width: 92%;
      max-height: 50vh;
      clear: both;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin: 3% 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
      border-radius: 10px;
      padding: 2% 3%;
      box-sizing: border-box;
      font-size: 12px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      .productList {
        width: 100%;
        // clear: both;
        overflow: hidden;

        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        .activeProduct {
          background-color: #525252;
          p {
            background-color: rgba(187, 187, 190, 0.8);
            color: #000;
          }
        }
        li {
          width: 50%;
          height: auto;
          float: left;
          text-align: center;
          background-color: #ededf0;
          padding: 2%;
          box-sizing: border-box;
          border-radius: 15px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: scale(0.8);
          cursor: pointer;
          img {
            max-width: 100%;
            max-height: 100%;
          }
          p {
            font-size: 12px;
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.45);
            line-height: 26px;
            color: #fff;
          }
        }
      }
    }
    .productSelect {
      width: 92%;
      font-size: 12px;
      overflow: hidden;
      color: #fff;
      margin: 5px auto 0;
      .activeSelect {
        color: #fff;
        background-color: #000;
      }
      li {
        min-width: 100px;
        width: 50%;
        float: left;
        transform: scale(0.8);
        background: #fff;
        color: #000;
        border: 1px solid #000;
        text-align: center;
        padding: 10px 0;
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
        &:last-child {
          width: 50%;
          position: relative;
          left: 50%;
          transform: translateX(-50%) scale(0.8);
        }
      }
    }
    .functionChange {
      position: relative;
      width: 92%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: space-around;
      margin-bottom: 4%;
      .activeTab {
        background-color: rgba(0, 0, 0, 0.2);
      }
      li {
        width: 19vw;
        max-width: 70px;
        height: 19vw;
        max-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 30%;
          height: auto;
          margin-bottom: 5px;
        }
        p {
          transform: scale(0.8);
          white-space: nowrap;
        }
      }
    }
  }
}
.power {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  bottom: 0%;
  width: 92%;
  max-width: 450px;
  text-align: center;
  z-index: 2;
}
</style>

