<template>
  <div class="login">
    <!-- logo -->
    <div class="logo">
      <img src="../assets/logo/logo.png" alt="" draggable="false" />
    </div>
    <!-- 登录表单 -->
    <div class="loginBox">
      <h4>会员登录</h4>
      <div class="form">
        <div class="oneInput">
          <img src="../assets/login/phone.png" alt="" /><span>手机号码</span>
        </div>
        <div class="textInput">
          <input
            type="text"
            autocomplete="off"
            v-model="loginName"
            placeholder="请输入手机号"
          />
          <!--  <div class="sendCode">发送验证码</div> -->
        </div>
        <div class="oneInput">
          <img src="../assets/login/password.png" alt="" /><span>密码</span>
        </div>
        <div class="textInput">
          <input
            type="password"
            autocomplete="off"
            v-model="password"
            placeholder="请输入密码"
          />
          <!--  <div class="sendCode">发送验证码</div> -->
        </div>
        <!--   <div class="oneInput">
          <img src="../assets/login/location.png" alt="" /><span>地区</span>
        </div>
        <div class="textInput">
          <input class="textInput" type="text" placeholder="请选择地区" />
        </div> -->

        <!-- <div class="oneInput">
          <img src="../assets/login/phone.png" alt="" /><span>绑定手机</span>
        </div>
        <div class="textInput">
          <input type="text" placeholder="请输入手机号" />
          <div class="sendCode">发送验证码</div>
        </div> -->
        <div class="protocol" @click="toreg">
          <label> 还没有账号，马上注册</label>
        </div>

        <div class="loginbtn">
          <button @click="doLogin">登录</button>
        </div>
        <div class="protocol" @click="toRepassword">
          <label> 找回登录密码</label>
        </div>
        <!--  <div class="loginbtn">
          <button @click="doLogin" id="wxLoginBtn">登录</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      protocolShow: false,
      isAgree: false,
      loginName: "",
      password: "",
    };
  },
  methods: {
    /* 
    wxLogin(){
      var obj = new WxLogin({
      self_redirect:true,
      id:"wxLoginBtn", 
      appid: "AppIDwx7a8f974595fb6c11", 
      scope: "snsapi_login", 
      redirect_uri: encodeURI("https://yetom.3dh5.vip"),
      state: "",
      style: "",
      href: ""
      });
    }, */
    changeProtocolShow() {
      this.protocolShow = false;
    },
    changeAgree(agree) {
      this.isAgree = !agree;
    },
          _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    toreg() {
      localStorage.removeItem("regForm");
      this.$router.push({ name: "reg" });
    },
    toRepassword() {
      localStorage.removeItem("regForm");
      this.$router.push({ name: "repassword" });
    },
    doLogin() {
      var _this = this;
      // console.log(this.$global);
      if (this.loginName == "") {
        this.$layer.msg("请输入用户名!");
        return false;
      }

      if (this.password == "" && this.password.length < 6) {
        this.$layer.msg("请正确输入登录密码!");
        return false;
      }

      var phoneType= 'app'
        if(!this._isMobile()){
          phoneType = 'pc'
        }

      this.$axios({
        method: "post",
        url:
          _this.$global.serverSrc +
          "/api/auth/pwd?loginName=" +
          this.loginName +
          "&password=" +
          this.password,
        // data: {loginName:this.regForm.loginName , password:this.regForm.password},
      }).then((res) => {
        if (res.data.code == 0) {
          this.$cookies.set("token", res.data.result.token, "24d");
          this.$cookies.set('userid' , res.data.result.id , '24d');
          this.$layer.msg("登录成功!");
          window.localStorage.setItem("loginName", this.loginName);
          if(  localStorage.getItem('shareCode')){
 //添加分享记录
            this.$axios({
              method: "post",
                url:  _this.$global.serverSrc+"/api/get/share/product?shareToken="+localStorage.getItem('shareCode')+'&visitType='+phoneType,
                }).then((res)=>{
                if(res.data.code==0){
                     localStorage.removeItem('shareCode')  
                     localStorage.setItem('shareJson' , JSON.stringify(res.data.result))
                     this.$router.push({name:'home' , query:{product:res.data.result.modelName.split('_')[1]}})
                }else{
                   this.$router.push({ name: "home", query: { product: "scene" } });
                }
            })  
          }else{
              this.$router.push({ name: "home", query: { product: "scene" } });
          }
          
        } else {
          this.$layer.msg(res.data.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  .logo {
    width: 36%;
    position: absolute;
    left: 4%;
    top: 2%;
    max-width: 210px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .loginBox {
    top: 22%;
    width: 92%;
    max-width: 650px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    h4 {
      letter-spacing: 0.1em;
    }
    .form {
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
      margin-top: 10px;
      font-size: 12px;
      .oneInput {
        width: 100%;
        margin-top: 15px;
        img {
          height: 16px;
          width: auto;
          vertical-align: text-bottom;
          margin-right: 3px;
          transform: scale(0.8);
        }
        span {
          font-size: 12px;
          line-height: 12px;
        }
      }
      .textInput {
        width: 100%;
        position: relative;
        input {
          width: 100%;
          padding-top: 10px;
          padding-left: 5px;
          padding-bottom: 10px;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #ededed;
          outline: none;
          border-radius: none;
          font-size: 14px;
          user-select: auto;
        }
        .sendCode {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .protocol {
        text-align: center;
        margin-top: 15px;
        input {
          vertical-align: text-bottom;
        }
      }
      .loginbtn {
        width: 100%;
        margin-top: 15px;
        button {
          width: 100%;
          background-color: #000;
          color: #fff;
          border-radius: 25px;
          padding: 10px 0;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>