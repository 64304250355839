<template>
  <div class="shareMask" @click="closeMask()">
    <div class="shareMask-box" id="capDiv">
      <!-- 关闭按钮  -->
      <!-- <div class="shareMask-close" @click="closeMask()">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div> -->
      <div class="shareLogo">
        <img src="@/assets/logo/logo.png" alt="" />
        <div class="shareText">
          <p>为您呈现我们所有的产品应用</p>
          <p>助您更深入了解盈通</p>
        </div>
      </div>
      <div class="shareImg">
        <img
          id="shareImg"
          ref="shareImg"
          :src="shareImg"
          alt=""
          draggable="false"
        />
      </div>
      <div class="shareBottom">
        <div class="qrcode" ref="qrCodeUrl">
          <img :src="qrcode" alt="" />
        </div>
        <div class="shareBottomText">
          <p class="p1">扫一扫打开应用</p>
          <p class="p2">立即开始您的<span class="s1">定制</span></p>
          <p class="p3">(长按保存截图)</p>
        </div>
      </div>
    </div>
    <div class="shareMask-box1">
      <img :src="allImg" alt="" />
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import html2canvas from "html2canvas";
export default {
  props: ["currentMaterial", "currentLines", "currentColors", "currenLogo"],
  data() {
    return {
      shareImg: "",
      loop: null,
      allImg: "",
      qrcode: "",
    };
  },
  watch: {},
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->

    // this.createQrCode();
    // console.log(1)
  },
  methods: {
    closeMask() {
      this.$emit("closeMask");
      // setTimeout(() => {
      //   this.shareImg = "";
      //   this.loop = null;
      //   this.allImg = "";
      // }, 500);
    },
    showDeleteButton() {
      var _this = this;
      // console.log(123);
      clearInterval(this.Loop); //再次清空定时器，防止重复注册定时器
      this.Loop = setTimeout(function () {
        html2canvas(document.querySelector("#capDiv")).then(function (canvas) {
          // console.log(canvas.toDataURL());
          var myBlob = _this.dataURLtoBlob(canvas.toDataURL());
          var myUrl = URL.createObjectURL(myBlob);
          _this.downloadFile(myUrl, "yetom-share" + new Date());
        });
      }, 1000);
    },
    getTouchEnd() {
      clearInterval(this.Loop); //再次清空定时器，防止重复注册定时器
    },
    createQrCode(img) {
      this.allImg = "";
      this.shareImg = "";
      let _this = this;
      var shareUrl = window.location.href;

      // 获取当前模型页
      if (this.$route.query.product === "scene" || !this.$route.query.product) {
        // console.log("当前在首页");
        let sceneCamera = v3d.puzzles.procedures.getCameraLocation();
        for (let i = 0; i < 3; i++) {
          sceneCamera.position[i] = parseFloat(sceneCamera.position[i]).toFixed(
            2
          );
          sceneCamera.rotate[i] = parseFloat(sceneCamera.rotate[i]).toFixed(2);
        }
        let position = sceneCamera.position.toString();
        let rotate = sceneCamera.rotate.toString();
        this.$router.push({
          query: {
            ...this.$route.query,
            position,
            rotate,
          },
        });
      } else {
        // 在产品页
        let productCamera = v3d.puzzles.procedures.recordCamera2();
        for (let i = 0; i < 3; i++) {
          productCamera.position[i] = parseFloat(
            productCamera.position[i]
          ).toFixed(2);
          productCamera.rotate[i] = parseFloat(productCamera.rotate[i]).toFixed(
            2
          );
        }

        let position = productCamera.position.toString();
        let rotate = productCamera.rotate.toString();
        this.$router.push({
          query: {
            ...this.$route.query,
            material: this.currentMaterial,
            lines: this.currentLines,
            color: this.currentColors,
            openCloth: v3d.puzzles.procedures.openCloth2(),
            position,
            rotate,
            logo: this.currenLogo,
            logoScale: document
              .getElementsByClassName("circleProgress")[0]
              .style.left.slice(
                0,
                document.getElementsByClassName("circleProgress")[0].style.left
                  .length - 1
              ),
            lightHight: v3d.puzzles.procedures.lightHight(),
          },
        });
      }
      // this.$refs.qrCodeUrl.innerHTML = "";
      var url =
        _this.$global.serverSrc +
        "/api/get/save/token?productId=" +
        this.$route.query.product;

      if (localStorage.getItem("sahreCode")) {
        url += "&shareToken=" + localStorage.getItem("shareCode");
      }
      //获取用户分享标识
      if (this.$route.query.product) {
        //  if(false){
        this.$axios({
          method: "post",
          url:
            _this.$global.serverSrc +
            "/api/get/save/token?productId=" +
            this.$route.query.product,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          if (res.data.code == 0) {
            shareUrl = shareUrl + "&shareCode=" + res.data.result;
            QRCode.toDataURL(shareUrl).then((imageData) => {
              _this.qrcode = imageData;
              this.shareImg = img;
              this.$refs.shareImg.onload = function () {
                html2canvas(document.querySelector("#capDiv")).then(function (
                  canvas
                ) {
                  _this.allImg = canvas.toDataURL();
                });
              };
            });
          }
        });
      } else {
        QRCode.toDataURL(shareUrl).then((imageData) => {
          _this.qrcode = imageData;
          this.shareImg = img;
          this.$refs.shareImg.onload = function () {
            html2canvas(document.querySelector("#capDiv")).then(function (
              canvas
            ) {
              _this.allImg = canvas.toDataURL();
            });
          };
        });
      }

      // setTimeout(function () {
      // }, 1000);
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    downloadFile(url, name = "What's the fuvk") {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      a.setAttribute("target", "_blank");
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
  },
};
</script>


<style lang="less">
.p1 {
  font-size: 12px;
  color: #333333;
}
.p2 {
  font-size: 16px;
  font-weight: 700;
}
.p3 {
  font-size: 12px;
  color: #717380;
}
.s1 {
  font-size: 20px;
}
.shareMask {
  touch-action: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  .shareMask-box1 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    max-width: 80%;
    max-height: 90%;
    // height: 500px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    // padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    img {
      width: 100%;
      height: auto;
      border-radius: 15px;
    }
  }
  .shareMask-box {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    max-width: 80%;
    max-height: 90%;
    // height: 500px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;

    .shareMask-close {
      position: absolute;
      width: 8%;
      max-width: 35px;
      filter: invert(100%);
      position: absolute;
      right: 0;
      bottom: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
        border: 1px solid #000;
        box-sizing: border-box;
        border-radius: 50%;
        padding: 3px;
      }
    }
    .shareLogo {
      height: 32px;
      margin-bottom: 15px;
      // overflow: hidden;
      position: relative;
      img {
        width: auto;
        max-width: 150px;
        height: 100%;
        vertical-align: bottom;
        float: left;
      }
      .shareText {
        position: absolute;
        right: -20px;
        top: 0;
        height: 100%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        transform: scale(0.7);
        text-align: right;
        line-height: 18px;
      }
    }
    .shareImg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        // max-width: 100%;
        // max-height: 100%;
      }
    }
    .shareBottom {
      width: 100%;
      height: 80px;
      // position: absolute;
      bottom: 0;
      margin-top: 10px;
      display: flex;
      .qrcode {
        width: 100px;
        height: 100px;
        float: left;
        // overflow: hidden;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 100%;
          height: 100%;
        }
      }
      img {
        height: 100%;
        width: auto;
        float: left;
      }
      .shareBottomText {
        float: left;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin-left: 3.5%;
      }
    }
  }
}
@media (min-width: 512px) {
  .shareMask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    .shareMask-box {
      position: absolute;
      height: 70%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 15px;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      /* align-items: center; */
      flex-direction: column;
      img {
        height: 100%;
      }
      .shareMask-close {
        position: absolute;
        width: 8%;
        max-width: 35px;
        filter: invert(100%);
        position: absolute;
        right: 0;
        bottom: 100%;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
          border: 1px solid #000;
          box-sizing: border-box;
          border-radius: 50%;
          padding: 3px;
        }
      }
      .shareLogo {
        height: 37px;
        // margin-bottom: 0px;
        // overflow: hidden;
        position: relative;
        img {
          width: auto;
          max-width: 300px;
          height: 100%;
          vertical-align: bottom;
          float: left;
        }
        .shareText {
          position: absolute;
          right: 0px;
          top: 0;
          height: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          transform: scale(1);
          text-align: right;
          line-height: 18px;
        }
      }
      .shareImg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
      .shareBottom {
        width: 100%;
        height: 100px;
        bottom: 0;
        .qrcode {
          width: 100px;
          height: 100%;
          float: left;
          // overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        img {
          height: 100%;
          width: auto;
          float: left;
        }
        .shareBottomText {
          float: left;
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          margin-left: 3.5%;
        }
      }
    }
  }
}
</style>