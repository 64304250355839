<template>
  <div class="contact">
    <div class="contact-box">
      <div class="contact-container" >
        <div class="contactImg" v-html="content.content"></div>  
         <!-- <img
          class="contactImg"
          src="@/assets/contact/contact.png"
          alt=""
          draggable="false"
        />  -->
        <div class="navPoint" @click="goNav()"></div>
        <div class="navImg">
          <img
            @click="goNav()"
            class=""
            src="@/assets/contact/nav.png"
            alt=""
            draggable="false"
          />
        </div>
      </div>
    </div>
    <div class="appSelect" v-if="showSelect" @click="showSelect = false">
      <dl class="appList">
        <dt>请选择</dt>
        <dd @click.stop="goApp(1)">腾讯地图</dd>
        <dd @click.stop="goApp(2)">百度地图</dd>
        <dd @click.stop="goApp(3)">高德地图</dd>
      </dl>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showSelect: false,
      content:{}
    };
  },
  mounted(){
    this.getData()
  },
  methods: {
    goNav() {
      this.showSelect = true;
    },

    getData(){
      var _this = this
      this.$axios({
            method: "post",
            url:  _this.$global.serverSrc+"/api/get/project/details?projectId=31"
      }).then((res)=>{
       this.content = res.data.result
      })
    },
    goApp(index) {
      let longitude = "113.000113",
        latitude = "22.632683";
      let name = "广东盈通新材料有限公司";
      let url = "";
      // if (isAndroid) {
      // } else if (isiOS) {
      switch (index) {
        case 1:
          longitude = "112.993667";
          latitude = "22.627085";
          const u = navigator.userAgent;
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          const isAndroid =
            u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
          if (isiOS || isAndroid) {
            url = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${latitude},${longitude};addr:${name}`;
          } else {
            url = `https://map.qq.com/?type=marker&isopeninfowin=1&markertype=1&pointx=${longitude}&pointy=${latitude}&name=广东盈通新材料有限公司&addr=${name}`;
          }
          break;
        case 2:
          longitude = "113.000113";
          latitude = "22.632683";
          // url = `baidumap://map/marker?location=${latitude},${longitude}&title=${name}&content=${name}&src=ios.baidu.openAPIdemo&coord_type=gcj02`;
          url = `http://api.map.baidu.com/marker?location=${latitude},${longitude}&title=${name}&content=${name}&output=html`;
          break;
        case 3:
          longitude = "112.993571";
          latitude = "22.626969";
          // url = `iosamap://viewMap?sourceApplication=applicationName&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0`;
          url = `http://uri.amap.com/marker?position=${longitude},${latitude}&name=${name}&coordinate=gaode&callnative=1`;
          break;
        case 4:
          // url = `itms-apps://itunes.apple.com/cn/app/%E8%85%BE%E8%AE%AF%E5%9C%B0%E5%9B%BE-%E8%B7%AF%E5%86%B5%E7%B2%BE%E5%87%86%E5%87%BA%E8%A1%8C%E5%BF%85%E5%A4%87%E7%9A%84%E5%AF%BC%E8%88%AA%E8%BD%AF%E4%BB%B6/id481623196?mt=8`;
          url = `iosamap://navi?sourceApplication=amap&poiname=&poiid=&lat=${latitude}&lon=${longitude}&dev=1&style=2`;
          break;
        default:
          break;
      }
      if (url != "") {
        window.open(url, "_blank");
      }

      // }
    },
  },
};
</script>
<style>
.contactImg  p img{
  width: 100% !important;
}
</style>
<style lang="less" scoped>

@media (max-width: 512px) {
  .contact {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    .appSelect {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      top: 0;
      z-index: 4;
      .appList {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        background-color: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        dt {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          padding: 4% 0;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.45);
        }
        dd {
          padding: 3% 0;
        }
      }
    }
    .contact-box {
      width: 92%;
      max-width: 450px;
      height: 100%;
      overflow: auto;
      margin: 0 auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .contact-container {
        position: relative;
        margin-bottom: 10%;
        .navPoint {
          position: absolute;
          width: 100%;
          height: 20%;
          background: transparent;
          bottom: 11%;
          cursor: pointer;
        }
        .contactImg {
          width: 100%;
          height: auto;
           p{
              width: 100%;
              img{
                width: 100%;
              }
           }
        }
        
        .navImg {
          position: absolute;
          width: 10%;
          bottom: 24.5%;
          right: 7%;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}
@media (min-width: 512px) {
  .contact {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    .appSelect {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      top: 0;
      z-index: 2;
      .appList {
        position: absolute;
        width: 50%;
        max-width: 450px;
        text-align: center;
        background-color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        dt {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          padding: 4% 0;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.45);
        }
        dd {
          padding: 3% 0;
        }
      }
    }
    .contact-box {
      width: 92%;
      max-width: 450px;
      height: 100%;
      overflow: auto;
      margin: 0 auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .contact-container {
        position: relative;
        margin-bottom: 10%;
        .navPoint {
          position: absolute;
          width: 100%;
          height: 20%;
          background: transparent;
          bottom: 11%;
        }
        .contactImg {
          width: 100%;
          height: auto;
           p{
              width: 100%;
              img{
                width: 100%;
              }
           }
        }
       
        .navImg {
          position: absolute;
          width: 10%;
          bottom: 24.5%;
          right: 7%;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}
.navImg {
  &::before {
    content: "";
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0%;
    height: 0%;
    // background-color: pink;
    border: 3px solid rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    box-sizing: border-box;
    opacity: 0;
    animation: scaleCircle 2s ease-in-out infinite;
    animation-delay: 0;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0%;
    height: 0%;
    // background-color: pink;
    border: 3px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    box-sizing: border-box;
    opacity: 0;
    animation: scaleCircle 2s ease infinite;
    animation-delay: 1s;
    pointer-events: none;
  }
}
@keyframes scaleCircle {
  0% {
    width: 0%;
    height: 0%;
    opacity: 0;
  }
  50% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
</style>