import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Reg from "../views/Reg.vue";
import Protocol from "../views/Protocol.vue";
import Repassword from "../views/repassword.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/Protocol",
    name: "Protocol",
    component: Protocol,
  },
  {
    path: "/reg",
    name: "reg",
    component: Reg,
  },
  {
    path: "/repassword",
    name: "repassword",
    component: Repassword,
  },
 
];

const router = new VueRouter({
  routes,
  mode: 'hash',
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
