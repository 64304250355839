<template>
  <div class="login">
    <!-- logo -->
    <div class="logo"   v-show="!protocolShow">
      <img src="../assets/logo/logo.png" alt="" draggable="false" />
    </div>
    <!-- 登录表单 -->
    <div class="loginBox"   v-show="!protocolShow">
       <div class="mask-close pc"  @click="doGoback()" >
                <img src="@/assets/home/back.png" class="closeImg" alt="" draggable="false" />
            </div>
            <div class="mask-close phone" @click="doGoback()">
                <img src="@/assets/home/back.png"  class="closeImg" alt="" draggable="false" />
            </div>
      <h4>找回密码</h4>
      <div class="form">
        <div class="oneInput">
          <img src="../assets/login/phone.png" alt="" /><span>手机号码</span>
        </div>
        <div class="textInput">
          <input type="text" autocomplete="off" v-model="regForm.loginName" placeholder="请输入手机号" />
          
        </div>
        <div class="oneInput">
          <img src="../assets/login/yzm.png" alt="" /><span>验证码</span>
        </div>
        <div class="textInput">
          <input type="text " autocomplete="off" v-model="regForm.yzm" placeholder="请输入验证码" />
           <div class="sendCode" @click="sendCode" >{{sendCodeMsg}}</div>  
        </div>
        <div class="oneInput">
          <img src="../assets/login/password.png" alt="" /><span>登录密码</span>
        </div>
        <div class="textInput">
          <input type="password" autocomplete="off" v-model="regForm.password" placeholder="请输入密码" />
         <!--  <div class="sendCode">发送验证码</div> -->
        </div>
        <div class="oneInput">
          <img src="../assets/login/password.png" alt="" /><span>确认密码</span>
        </div>
        <div class="textInput">
          <input type="password" autocomplete="off" v-model="rePassword" placeholder="请输入再次密码" />
         <!--  <div class="sendCode">发送验证码</div> -->
        </div>
          
        <div class=" textInput ">
          <button @click="doSubmit" class="loginbtn" >提交</button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
 
export default {
 
  data() {
    return {
      protocolShow: false,
      isAgree: false,
      sendCodeMsg:'发送验证码',
      codeStatus:false,
      regForm:{
          loginName:'',
          password:'',
          yzm:'',
      },
      rePassword:'',
      code:''
    };
  },
  mounted(){
 
   
  },
  methods: {
    
    doSubmit(){
        var _this = this
        if(this.codeStatus!=true){
           this.$layer.msg("请先获取验证码!");
           return false
        }
        if(this.regForm.loginName=='' || this.regForm.loginName.length != 11 ){
         
           this.$layer.msg("请输入手机号码!");
           return false
        } 
        if(this.regForm.yzm==''){
          this.$layer.msg("请输验证码!");
           return false
        }
        if(this.regForm.password=='' || this.regForm.password.length<6){
          this.$layer.msg("请正确输入密码!");
           return false
        }
        if(this.regForm.password!=this.rePassword){
           this.$layer.msg("请正确输入密码!");
           return false
        }
         if(this.regForm.yzm!=this.code){
           this.$layer.msg("验证码有误!");
           return false
        }
      
          this.$axios({
            method: "post",
            url:  _this.$global.serverSrc+"/api/update/pwd?loginName="+this.regForm.loginName+'&password='+this.regForm.password+'&code='+this.regForm.yzm,
           // data: {loginName:this.regForm.loginName , password:this.regForm.password},
            }).then((res)=>{
            if(res.data.code==0){
                    _this.$layer.msg("密码修改成功，请登录!");
                    _this.$router.push({name:'login'})
                 
             
            }
         })
    },
    doGoback(){
      this.$router.back()
    },
    sendCode(){
      var _this = this
       if(this.regForm.loginName=='' || this.regForm.loginName.length != 11){
         
           this.$layer.msg("请输入手机号码!");
           return false
        } 
         this.$axios({
            method: "post",
            url: _this.$global.serverSrc+ "/api/send/code?phone="+this.regForm.loginName+'&type=1',
           // data: {loginName:this.regForm.loginName , password:this.regForm.password},
            }).then((res)=>{
            if(res.data.code==0){
              this.$layer.msg("验证码获取成功");
              this.showTimes()
              this.codeStatus = true
              this.code = res.data.result
            }
          })

       
    },
    showTimes(time = 60){
        time--
        this.sendCodeMsg = time
      
        var _this = this
        setTimeout(function(){
            if(time>0){
                _this.showTimes(time)
            }else{
               _this.sendCodeMsg = '重新获取'
            }
        },1000)
    }
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  .logo {
    width: 36%;
    position: absolute;
    left: 4%;
    top: 2%;
    max-width: 210px;
    img {
      width: 100%;
      height: auto;
    }
  }
  
}
.regContent{
  cursor: pointer;
}
.loginBox {
    top: 50%;
    width: 92%;
    max-width: 650px;
    position: absolute;
    left: 50%;
    transform: translate(-50% , -50%);
    padding: 10px;
    max-height: 80%;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    .mask-close{
      float: right;
        .closeImg{
            width: 15px;
            height: 15px;
        }
  }
    h4 {
      letter-spacing: 0.1em;
    }
    .form {
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
      margin-top: 10px;
    
      font-size: 12px;
      .oneInput {
        width: 100%;
        margin-top: 10px;
        img {
          height: 16px;
          width: auto;
          vertical-align: text-bottom;
          margin-right: 3px;
          transform: scale(0.8);
        }
        span {
          font-size: 12px;
          line-height: 12px;
        }
      }
      .textInput {
        width: 100%;
        position: relative;
        input {
          width: 100%;
          padding-top: 10px;
          padding-left: 5px;
          padding-bottom: 10px;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #ededed;
          outline: none;
          border-radius: none;
          font-size: 14px;
          user-select: auto;
        }
        .sendCode {
          position: absolute;
          right: 0;
          top: 50%;
          width: 100px;
          line-height: 20px;
          text-align: center;
          border-radius: 5px;
          background-color: #ccc;
          transform: translateY(-50%);
         
        }
      }
      .protocol {
        text-align: center;
        margin-top: 15px;
        cursor: pointer;
      }
      .loginbtn {
          width: 100%;
          background-color: #000;
          color: #fff;
          border-radius: 25px;
          padding: 10px 0;
          box-sizing: border-box;
       
      }
    }
  }
</style>