<template>
  <div class="mask introduction">
    <div class="maskBox">
      <!-- logo -->
      <div class="mask-logo">
        <img src="@/assets/logo/logo.png" alt="" draggable="false" />
      </div>
      <!-- 关闭按钮  -->
      <div class="mask-close" @click="closeMask">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <!-- 弹窗内容 -->
      <div class="mask-container">
        <div class="videoResource">
          <video
            controls
            autoplay
            webkit-playsinline="true"
            playsinline="true"
            src="@/assets/video/product.mp4"
          ></video>
        </div>
        <div class="circle">
          <h4>公司简介</h4>
            <div v-html="content"></div>
        </div>
        <div class="profile">
          <img src="@/assets/home/mask/profile.png" alt="" draggable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       content:'' 
    }
  },
 
  mounted(){
      this.getContent()
  },
  methods: {
    closeMask(){
      this.$emit("closeMask");
    },
    getContent(){
       
      var _this = this
          _this.$axios
          .post(
            _this.$global.serverSrc+"/api/get/hall/info/list?siteId=7",
            {}
          ).then((res)=>{
             var sUserAgent = navigator.userAgent.toLowerCase();
          if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
          //跳转移动端页面
              this.content = res.data.result[2].appContent
          } else {
          //跳转pc端页面
          this.content = res.data.result[2].pcContent
          }
            
          })
   
    }
  }
};
</script>

<style>
</style>