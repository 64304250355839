<template>
  <div class="mask logoWall">
    <div class="wallBox">
      <div class="mask-close" @click="closeMask()">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <div class="boxSize">
        <div class="logoImg">
          <div v-html="content">
             
          </div>
         <!-- 
          <img
            class="mylogo"
            src="@/assets/home/mask/wall/logo2.jpg"
            alt=""
            draggable="false"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       content:'' 
    }
  },
 
  mounted(){
    /*   this.getContent() */
  },
  methods: {
    closeMask(){
      this.$emit("closeMask");
    },
    showContent(index){
      // console.log(index)
       var i =0
       switch(index){
         case 5:
           i=0 
           break
         case 6:
           i=1 
           break
         case 7:
           i=2 
           break
         case 8:
           i=3 
           break
         default:
           i=0
           break        
       }
      var _this = this
          _this.$axios
          .post(
            _this.$global.serverSrc+"/api/get/hall/info/list?siteId=9",
            {}
          ).then((res)=>{
            var sUserAgent = navigator.userAgent.toLowerCase();
            if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
            //跳转移动端页面
                this.content = res.data.result[i].appContent
            } else {
            //跳转pc端页面
              this.content = res.data.result[i].pcContent
            }
                // console.log(this.content)
          })

      
   
    }
  }
};
</script>
<style>
</style>