<template>
  <div
    class="home"
    style="width: 100%; height: 100%; overflow: hidden; position: absolute"
  >
    <V3DApp />
  </div>
</template>

<script>
// @ is an alias to /src
import V3DApp from "../components/V3DApp.vue";
export default {
  name: "App",
  components: {
    V3DApp,
  },
  methods:{
      _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted(){
    var _this = this
      if(this.$route.query.shareCode){
          localStorage.setItem('shareCode' , this.$route.query.shareCode)
      }
       var phoneType= 'app'
        if(!this._isMobile()){
          phoneType = 'pc'
        }

       
        if(this.$cookies.isKey('token')){
          // console.log(this.$cookies.get('token'))
          if(  localStorage.getItem('shareCode')){
 //添加分享记录
            this.$axios({
              method: "post",
                url:  _this.$global.serverSrc+"/api/get/share/product?shareToken="+localStorage.getItem('shareCode')+'&visitType='+phoneType,
                }).then((res)=>{
                if(res.data.code==0){
                     localStorage.removeItem('shareCode')  
                     localStorage.setItem('shareJson' , JSON.stringify(res.data.result))
                     this.$router.push({name:'home' , query:{product:res.data.result.modelName.split('_')[1]}})
                }else{
                  localStorage.removeItem('shareJson')
                   this.$router.push({ name: "home", query: { product: "scene" } });
                }
            })  
          }

        }else{
            this.$router.push({name:"login"})
        }
                  var _this = this
                  _this.$axios
                  .post(
                    _this.$global.serverSrc+"/api/get/hall/info/list?siteId=8",
                    {}
                  ).then((res)=>{
                    var  list = res.data.result
                      const handle = (property) => {
                          return function(a,b){
                              const val1 = a[property];
                              const val2 = b[property];
                              return val1 - val2;
                          }
                      }
                  list.sort(handle('actionId'));
                  localStorage.setItem('machineImg' , JSON.stringify(list) )
                  })

           

       
  }
};
</script>
