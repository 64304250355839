<template>
  <div class="mask partner" >
    <div class="maskBox">
      <!-- logo -->
      <div class="mask-logo">
        <img src="@/assets/logo/logo.png" alt="" draggable="false" />
      </div>
      <!-- 关闭按钮  -->
      <div class="mask-close" @click="closeMask">
        <img src="@/assets/home/mask/close.png" alt="" draggable="false" />
      </div>
      <!-- 弹窗内容 -->
      <div class="mask-container" v-html="content">
       <!--  <h4>全球户外运动服饰品牌</h4>
        <img src="@/assets/home/mask/partner1.png" alt="" draggable="false" />
        <h4>快时尚，休闲服饰品牌</h4>
        <img src="@/assets/home/mask/partner2.png" alt="" draggable="false" />
        <h4>中国户外及体育品牌</h4>
        <img src="@/assets/home/mask/partner3.png" alt="" draggable="false" /> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
       content:'' 
    }
  },
 
  mounted(){
      this.getContent()
  },
  methods: {
    closeMask(){
      this.$emit("closeMask");
    },
    getContent(){
       
      var _this = this
          _this.$axios
          .post(
            _this.$global.serverSrc+"/api/get/hall/info/list?siteId=7",
            {}
          ).then((res)=>{
            var sUserAgent = navigator.userAgent.toLowerCase();
          if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
          //跳转移动端页面
              this.content = res.data.result[3].appContent
          } else {
          //跳转pc端页面
          this.content = res.data.result[3].pcContent
          }
            
          })
   
    }
  }
};
</script>

<style>
</style>